import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import productsReducer from "./features/products/productSlice";
import colorReducer from "./features/colorSlice/colorSlice";
import cartReducer from "./features/cart/cartSlice";
import userReducer from "./features/user/userSlice";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ["cart", "user"] // Specify the states you want to persist
}

const rootReducer = combineReducers({
    products: productsReducer,
    colors: colorReducer,
    cart: cartReducer,
    user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;