import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cart: null,
    customerInfo: null,
    activeProduct: null,
    activeOrder: null,
}

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setCart: (state, action) => {
            state.cart = action.payload;
        },
        setCustomerInfo: (state, action) => {
            state.customerInfo = action.payload;
        },
        setActiveProduct: (state, action) => {
            state.activeProduct = action.payload;
        },
        setActiveOrder: (state, action) => {
            state.activeOrder = action.payload;
        }
    }
});

export const { setCart, setCustomerInfo, setActiveProduct, setActiveOrder } = cartSlice.actions;
export default cartSlice.reducer;
