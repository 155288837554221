import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setActiveProduct } from '../../store/features/cart/cartSlice';

const ProductCard = ({ product }) => {

    const [hardwareDiscount, setHardwareDiscount] = useState(0);
    const brandName = product.devicename.split(" ")[0];
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleProductClick = () => {
        dispatch(setActiveProduct(product.id));
        navigate("/singleproduct");
    }

    useEffect(() => {
        if (product) {
            const discountKeys = [
                'low_monthly_hd_bib',
                'low_monthly_hd_non_bib',
                'zero_up_hd_bib',
                'zero_up_hd_non_bib'
            ];

            const maxDiscount = discountKeys.reduce((max, key) => {
                const value = parseFloat(product[key]);
                return value > max ? value : max;
            }, 0);

            setHardwareDiscount(maxDiscount);
        }
    }, [product]);

    return (
        <div onClick={handleProductClick} className="cursor-pointer relative w-full mx-auto bg-white shadow-sm border border-gray-300 rounded-lg overflow-hidden">
            <div className="relative p-4">
                <span className="absolute top-0 left-0 bg-themeColor text-white text-xs font-semibold px-2 py-1 rounded-br-lg">Bring-It-Back</span>
                <div className="flex justify-center mt-4">
                    <img className="object-cover w-[150px]" src={process.env.REACT_APP_BASE_URL + "/images/" + product.device_images[0]} alt={product.devicename_new} />
                </div>
            </div>
            <div className="p-4 mb-2">
                <h2 className="text-lg font-semibold text-gray-800">{product.devicename_new}</h2>
                <p className="text-gray-600">{brandName}</p>
                <div className="flex items-center justify-between mt-4">
                    <div className='pb-5'>
                        <p className="text-gray-800 font-semibold">${Number(product.low_monthly_monthly_bib || 0).toFixed(2)}<span className="text-gray-500 text-sm">/mo</span></p>
                        <p className="text-gray-500 text-sm">Pay Monthly</p>
                    </div>
                    <div className='pb-5'>
                        <p className="text-gray-800 font-semibold">${product.zero_up_customer_bib}<span className="text-gray-500 text-sm"> Upfront</span></p>
                        <p className="text-gray-500 text-sm">0%</p>
                    </div>
                </div>
            </div>
            {hardwareDiscount !== 0 &&
                <div className="w-full absolute bottom-0 text-center bg-[#80b635] text-white text-[16px] font-semibold p-2 flex justify-center items-center">
                    <span>${hardwareDiscount} Hardware Discount</span>
                </div>
            }
        </div>
    );
};

export default memo(ProductCard);
