import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deviceColors:
    {
        midnight: "#422322",
        starlight: "#422322",
        red: "#422322",
        black: "#422322",
        blue: "#422322",
        bink: "#422322",
        purple: "#422322",
        yellow: "#422322",
        space_black: "#422322",
        silver: "#422322",
        gold: "#422322",
        green: "#422322",
        natural: "#422322",
        white: "#422322",
        hazel: "#422322",
        snow: "#422322",
        grey: "#422322",
        graphite: "#422322",
        phantom_black: "#422322",
        bora_purple: "#422322",
        cream: "#422322",
        lavendar: "#422322",
        pink_gold: "#422322",
        ocean_green: "#422322",
        polar_blue: "#422322",
        deep_indigo: "#422322",
        eclipse_black: "#422322",
        charcoal: "#422322",
        beige: "#422322",
        moonlight_gray: "#422322",
    }

}

export const colorSlice = createSlice({
    name: "colors",
    initialState,
    reducers: {

    }
});

export default colorSlice.reducer;
