import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allProducts: [],
}

export const productSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        setAllProducts: (state, action) => {
            state.allProducts = action.payload;
        },
    }
});

export const { setAllProducts } = productSlice.actions;
export default productSlice.reducer;
