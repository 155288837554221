import { Button, Modal, Collapse, ConfigProvider, Form, Input, Select, Checkbox } from 'antd';
import React, { useState } from 'react';
import { PlayCircleOutlined } from '@ant-design/icons';
import discountImage from "../../assets/image/landing-img-1.webp";
import supportImage from "../../assets/image/landing-img-2.webp";
import familySupportImage from "../../assets/image/landing-img-3.webp";
import { BsSpeedometer } from "react-icons/bs";
import { FaTabletScreenButton } from "react-icons/fa6";
import { GiInfinity } from "react-icons/gi";
import { Link } from 'react-router-dom';
import teluslogo from "../../assets/image/telus.jpg";

const { Panel } = Collapse;
const { Option } = Select;

const LandingPage = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSubmit = async (values) => {
        try {
            const response = await fetch('https://boltwireless.ca/sendemail/send.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(values).toString(),
            });

            if (response.ok) {
                console.log('Form submitted successfully');
            } else {
                console.error('Form submission failed');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const currentYear = new Date().getFullYear();

    return (
        <div>
            <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 py-8 overflow-hidden">
                <h1 className='text-3xl sm:text-4xl font-bold text-themeColor text-center'>TELUS Exclusive Partner Program (EPP)</h1>
                <p className='text-center text-gray-700 mt-3'>
                    We’ve partnered with workplaces and organizations across Canada to set up exclusive savings <br className='hidden md:block' />
                    for employees and their families
                </p>
                <div className="flex justify-center items-center">
                    <Link to="/eligibleform">
                        <Button type='primary' size='large' className='mt-4 font-medium bg-themeLightGreen hover:opacity-[0.8] duration-300'>
                            Check Eligibility
                        </Button>
                    </Link>
                </div>

                {/* Video Section */}
                <div className="mt-10 flex justify-center items-center relative w-full">
                    <div className="relative w-[90%] sm:w-3/4 flex justify-center">
                        {/* Video Thumbnail with Overlay */}
                        <img
                            src="https://boltwireless.ca/wp-content/uploads/2024/06/Telus-Koodo-store-in-Calgary-SW-AB-1024x577.png"
                            alt="Video Thumbnail"
                            className="w-full h-auto object-cover rounded-md shadow-lg"
                        />
                        {/* Overlay */}
                        <div className="absolute inset-0 bg-[#4B286D] opacity-70 rounded-md"></div>
                        {/* Play Icon */}
                        <PlayCircleOutlined
                            className="absolute text-white text-6xl cursor-pointer opacity-80 hover:opacity-100 duration-300 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                            onClick={showModal}
                        />
                    </div>
                </div>

                {/* Video Modal */}
                <Modal
                    title={null}
                    open={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                    centered
                    style={{ padding: 0 }}
                    className='w-full sm:w-[70%]'
                >
                    <video
                        src="https://boltwireless.ca/wp-content/uploads/2024/04/WhatsApp-Video-2024-04-09-at-10.01.30-AM.mp4"
                        controls
                        autoPlay
                        className="w-full h-auto"
                    />
                </Modal>
            </div>
            <div className='w-full my-[40px] py-[40px] bg-gray-200'>
                <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 overflow-hidden">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-20 items-center">
                        <div>
                            <h2 className='text-3xl font-bold text-themeColor leading-normal'>Save big on Phones, Plans, Internet, TV and Home Security</h2>
                            <p className='text-gray-700 mt-3 text-[16px] md:text-[18px] leading-relaxed'>
                                Get up to $30 off per month on unlimited data plans for phones. Plus, enjoy incredible deals on smartphones, tablets, PureFibre Internet, Optik TV and SmartHome Security.
                            </p>
                            <div className='flex gap-4'>
                                <Link to="/productlist">
                                    <Button type='primary' size='large' className='mt-4 font-medium bg-themeLightGreen hover:opacity-[0.8] duration-300'>
                                        Order Now
                                    </Button>
                                </Link>
                                <Link to="https://boltwireless.ca/contact-us/" target='_blank'>
                                    <Button type='primary' size='large' className='mt-4 font-medium bg-themeColor hover:opacity-[0.8] duration-300'>
                                        Contact Us
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <img src={discountImage} alt="discount" className='w-full' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full my-[40px] py-[40px]'>
                <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 overflow-hidden">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-20 items-center">
                        <div>
                            <img src={supportImage} alt="support" className='w-full' />
                        </div>
                        <div>
                            <h2 className='text-3xl font-bold text-themeColor leading-normal'>Enjoy Dedicated customer service at every step</h2>
                            <p className='text-gray-700 mt-3 text-[16px] md:text-[18px] leading-relaxed'>
                                Get personalized support from our Exclusive Partner Program team of experts by phone, email or chat.
                            </p>
                            <div className='flex gap-4'>
                                <Link to="/productlist">
                                    <Button type='primary' size='large' className='mt-4 font-medium bg-themeLightGreen hover:opacity-[0.8] duration-300'>
                                        Order Now
                                    </Button>
                                </Link>
                                <Link to="https://boltwireless.ca/contact-us/" target='_blank'>
                                    <Button type='primary' size='large' className='mt-4 font-medium bg-themeColor hover:opacity-[0.8] duration-300'>
                                        Contact Us
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full mt-[40px] py-[40px] bg-gray-200'>
                <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 overflow-hidden">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-20 items-center">
                        <div>
                            <h2 className='text-3xl font-bold text-themeColor leading-normal'>Share your Benefits with family</h2>
                            <p className='text-gray-700 mt-3 text-[16px] md:text-[18px] leading-relaxed'>
                                Add up to nine loved ones to your account and share all your Exclusive Partner Program perks with them. You can all enjoy travel discounts, savings on device repairs and more.
                            </p>
                            <div className='flex gap-4'>
                                <Link to="/productlist">
                                    <Button type='primary' size='large' className='mt-4 font-medium bg-themeLightGreen hover:opacity-[0.8] duration-300'>
                                        Order Now
                                    </Button>
                                </Link>
                                <Link to="https://boltwireless.ca/contact-us/" target='_blank'>
                                    <Button type='primary' size='large' className='mt-4 font-medium bg-themeColor hover:opacity-[0.8] duration-300'>
                                        Contact Us
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <img src={familySupportImage} alt="family" className='w-full' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full mb-[40px] pt-[60px] bg-themeLightGreen'>
                <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 overflow-hidden">
                    <h2 className='text-3xl sm:text-4xl font-bold text-themeColor text-center mb-[50px]'>Join Canada’s most-awarded network</h2>
                    <div className='grid grid-cols-1 sm:grid-cols-3 gap-6 pt-4'>
                        <div className='text-center px-5 py-8 bg-themeColor rounded-xl shadow-2xl border border-black'>
                            <div className='flex justify-center'>
                                <BsSpeedometer className='text-[50px] text-center text-themeLightGreen font-bold m-3' />
                            </div>
                            <p className='text-[16px] sm:text-[18px] leading-relaxed mt-3 text-white'>
                                Enjoy blazing-fast 5G speeds and a reliable connection coast-to-coast6
                            </p>
                        </div>
                        <div className='text-center px-5 py-8 bg-themeColor rounded-xl shadow-2xl border border-black'>
                            <div className='flex justify-center'>
                                <FaTabletScreenButton className='text-[50px] text-center text-themeLightGreen font-bold m-3' />
                            </div>
                            <p className='text-[16px] sm:text-[18px] leading-relaxed mt-3 text-white'>
                                Get unlimited data, talk and texts with no overages
                            </p>
                        </div>
                        <div className='text-center px-5 py-8 bg-themeColor rounded-xl shadow-2xl border border-black'>
                            <div className='flex justify-center'>
                                <GiInfinity className='text-[50px] text-center text-themeLightGreen font-bold m-3' />
                            </div>
                            <p className='text-[16px] sm:text-[18px] leading-relaxed mt-3 text-white'>
                                Incredible savings on the latest phones, all at $0 upfront plus taxes7
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center items-center py-10">
                        <Link to="/eligibleform">
                            <Button type='primary' size='large' className='mt-4 font-medium'>
                                Check Eligibility
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='w-full my-[40px] py-[40px] px-[20px] sm:px-[140px]'>
                <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 overflow-hidden">
                    {/* FAQ Section */}
                    <h2 className='text-3xl font-bold text-themeColor text-center mb-14'>Frequently Asked Questions</h2>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorTextBase: '#4B286D',
                            },
                        }}
                    >
                        <Collapse
                            accordion
                            className="shadow-lg rounded-lg custom-collapse"
                            expandIconPosition="right"
                        >
                            <Panel
                                header={<span className="text-[16px] md:text-[18px] font-medium">How do I know if my workplace is eligible for the Exclusive Partner Program?</span>}
                                key="1"
                            >
                                <p className='text-gray-700 text-[16px] leading-loose'>
                                    Check <Link to="/landingpage" className='text-themeGreen'>here</Link>  to see if your company is eligible for the TELUS Exclusive Partner Program.
                                </p>
                                <p className='text-gray-700 text-[16px] leading-loose'>
                                    If you encounter an issue when checking your eligibility, contact  <Link to="tel:7789578000" className='text-themeGreen'>778 957 8000.</Link>
                                </p>
                            </Panel>
                            <Panel
                                header={<span className="text-[16px] md:text-[18px] font-medium">Where can I find my Exclusive Partner Program offers?</span>}
                                key="2"
                            >
                                <p className='text-gray-700 text-[16px] leading-loose'>
                                    Once your eligibility has been confirmed, you will receive an email with a link to access your exclusive discounts and limited time offers.
                                </p>
                            </Panel>
                            <Panel
                                header={<span className="text-[16px] md:text-[18px] font-medium">Can I go to my local BoltWireless TELUS Koodo Store to join the Exclusive Partner Program?</span>}
                                key="3"
                            >
                                <p className='text-gray-700 text-[16px] leading-loose'>
                                    All TELUS corporate stores and select dealer locations offer the Exclusive Partner Program. <Link to="/landingpage" className='text-themeGreen'>Find your nearest TELUS store.</Link>
                                </p>
                            </Panel>
                            <Panel
                                header={<span className="text-[16px] md:text-[18px] font-medium">Can I keep my phone number from a different mobile provider?</span>}
                                key="4"
                            >
                                <p className='text-gray-700 text-[16px] leading-loose'>
                                    Yes. You can port your number over from a different service provider and become a new TELUS Exclusive Partner Program customer.
                                </p>
                                <p className='text-gray-700 text-[16px] leading-loose font-bold'>
                                    Connection fee discounts may be available to new EPP customers.
                                </p>
                            </Panel>
                            <Panel
                                header={<span className="text-[16px] md:text-[18px] font-medium">I already have a TELUS consumer account. Can I keep my phone number?</span>}
                                key="5"
                            >
                                <p className='text-gray-700 text-[16px] leading-loose'>
                                    Yes. You can keep your number and move from a Consumer plan to a Exclusive Partner Program plan. A one-time $50 account migration fee applies.
                                </p>
                            </Panel>
                            <Panel
                                header={<span className="text-[16px] md:text-[18px] font-medium">What services are available for bundling in my province?</span>}
                                key="6"
                            >
                                <p className='text-gray-700'>
                                    Pure Fibre Internet and Optik TV are available in British Columbia, Alberta, and Quebec. Altima High-Speed Internet is available in Ontario, Quebec and Manitoba. Mobility and SmartHome Security are available in all provinces.
                                </p>
                            </Panel>
                            <Panel
                                header={<span className="text-[16px] md:text-[18px] font-medium">What if I don’t have a corporate/work email?</span>}
                                key="7"
                            >
                                <p className='text-gray-700'>
                                    Contact <Link to="tel:7789578000" className='text-themeGreen'>778 957 8000.</Link> to speak to an agent and explore your other options.
                                </p>
                            </Panel>
                            <Panel
                                header={<span className="text-[16px] md:text-[18px] font-medium">I have encountered an issue. How do I contact customer service?</span>}
                                key="8"
                            >
                                <p className='text-gray-700'>
                                    Contact <Link to="tel:7789578000" className='text-themeGreen'>778 957 8000.</Link>  to speak to a customer service representative.
                                </p>
                            </Panel>
                        </Collapse>
                    </ConfigProvider>
                </div>
            </div>
            <div className='w-full my-[40px] py-[40px] px-[20px] sm:px-[140px]'>
                <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 overflow-hidden">
                    <h2 className='text-3xl font-bold text-themeColor text-center mb-14'>Terms & Conditions</h2>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorTextBase: '#4B286D',
                            },
                        }}
                    >
                        <Collapse
                            accordion
                            className="shadow-lg rounded-lg custom-collapse"
                            expandIconPosition="right"
                        >
                            <Panel
                                header={<span className="text-[16px] md:text-[18px] font-medium">Terms & Conditions</span>}
                                key="1"
                            >
                                <p className='text-gray-700 leading-loose'>
                                    <ol className='"list-decimal space-y-4 px-3' style={{ listStyle: "auto" }}>
                                        <li>Subject to approved credit with a 24-month TELUS Easy Payment® agreement (0% APR) on any in-market plan with data and voice included. TELUS Easy Payment® balance will be repaid over a 24 month period via equal monthly payments. Payment of any outstanding TELUS Easy Payment® balance due upon termination, or account transfer. Pay-per-use charges (including long distance, roaming and additional airtime or data) are extra.</li>
                                        <li>Proof of eligible employment required for all Exclusive Partner Program (“EPP”) offers. Offer available at dealers and corporate stores that support the EPP. Offer only available with a 2-year TELUS Easy Payment term. Offer includes a $80 device discount for the Samsung S24 256GB, a $160 device discount for the Samsung S24+ 512GB, or a $160 device discount for the Samsung S24 Ultra 512 GB. Device discount is a one-time discount, applied to the total cost of the device. Device discount can only be used towards the cost of the device and is non-refundable, and carries no cash value if deactivating services. While supplies last. TELUS reserves the right to change or withdraw the offer at any time. Requires internet connection; results may vary by uniqueness, clarity and framing of circled image and related factors.</li>
                                        <li>Proof of eligible employment required for all Exclusive Partner Program (“EPP”) offers. Offer available at dealers and corporate stores that support the EPP. Taxes (including applicable provincial or municipal government 911 fees) and pay-per-use charges (including long distance, roaming and additional airtime or additional data) are extra. Visit telus.com/mobilityppu for details. Rates and offers are subject to change without notice. Data speeds reduced to a maximum of 512Kbps after your included high-speed data bucket is exhausted. Speed may vary with your device, internet traffic, environmental conditions, and other factors. Please refer to TELUS’ Fair Use Policy at telus.com/fairusepolicy for further information. 5G access requires a compatible device and connection to TELUS’ 5G network where available. Speed and signal strength may vary with your device, configuration, Internet traffic, environmental conditions, applicable network management and other factors. For more information, visit telus.com/coverage. Subject to approved credit with a 24 month Easy Payment agreement (0% APR) on any in-market plan with data and voice included. Easy Payment balance will be repaid over a 24 month period via equal monthly payments. Payment of any outstanding Easy Payment balance due upon termination, or account transfer. Applicable taxes are calculated based on the total no term price less any device discounts and must be paid in full at the time of purchase. Pay-per-use charges (including long distance, roaming and additional airtime or data) are extra.</li>
                                        <li>Proof of eligible employment required for all Exclusive Partner Program (“EPP”) offers. Offer available at dealers and corporate stores that support the EPP. Customers from select organizations signing up on an EPP account with an eligible Bring Your Own device plan at $50 and more will save an additional $120. Offer available for new activations only. The bill credit will be applied as a $5 per month credit for 24 months. Offer not available for renewals or at rate plan change. Offer not eligible for connected device transactions such as smartwatches and tablets.</li>
                                        <li>Available to TELUS Mobility customers who purchase qualifying TELUS Home Services product(s). A $10/month discount will be applied to the Home Services bill for 24 months (or as long as the eligibility requirements are met). Eligible Home Services products include TELUS Home Internet 15 and higher, Optik TV, Home Phone, TELUS SmartHome Security or combination thereof. Offers subject to change without notice. Select products only available in Alberta and British Columbia.</li>
                                        <li>According to total wins secured by TELUS in the Opensignal Awards – Canada: Mobile Network Experience and 5G Experience Reports February 2023, based on independent analysis of mobile measurements recorded during the period Oct 1 – Dec 29, 2022 © 2023 Opensignal Limited.</li>
                                        <li>Subject to approved credit with a 24-month TELUS Easy Payment® agreement (0% APR) on any in-market plan with data and voice included. TELUS Easy Payment® balance will be repaid over a 24 month period via equal monthly payments. Payment of any outstanding TELUS Easy Payment® balance due upon termination, or account transfer. Pay-per-use charges (including long distance, roaming and additional airtime or data) are extra.</li>
                                    </ol>
                                </p>
                            </Panel>
                        </Collapse>
                    </ConfigProvider>
                </div>
            </div>
            <div className='w-full my-[40px] py-[40px] bg-themeLightGreen'>
                {/* <div className="max-w-4xl mx-auto container px-4 sm:px-6 lg:px-8 overflow-hidden">
                    <h2 className="text-3xl font-bold text-themeColor text-center mb-14">Secure Your Desired Device with the Perfect Plan</h2>
                    <Form
                        layout="vertical"
                        initialValues={{ existingProvider: 'Telus' }}
                        className="mx-auto max-w-screen-md"
                        onFinish={handleSubmit}
                    >
                        <div className='grid grid-cols-1 gap-2 md:gap-4 sm:grid-cols-2'>
                            <Form.Item
                                label={<span className="text-white">Name</span>}
                                name="name"
                                rules={[{ required: true, message: 'Please input your name!' }]}
                            >
                                <Input placeholder="Other" />
                            </Form.Item>

                            <Form.Item
                                label={<span className="text-white">Email</span>}
                                name="email"
                                rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                            >
                                <Input placeholder="Enter your email address" />
                            </Form.Item>

                            <Form.Item
                                label={<span className="text-white">Phone</span>}
                                name="phone"
                                rules={[{ required: true, message: 'Please input your phone number!' }]}
                            >
                                <Input placeholder="Enter your phone number" />
                            </Form.Item>

                            <Form.Item
                                label={<span className="text-white">Address</span>}
                                name="address"
                                rules={[{ required: true, message: 'Please input your address!' }]}
                            >
                                <Input placeholder="Enter your address" />
                            </Form.Item>

                            <Form.Item
                                label={<span className="text-white">Existing Provider</span>}
                                name="existingProvider"
                                rules={[{ required: true, message: 'Please select your existing provider!' }]}
                            >
                                <Select placeholder="Select your existing provider">
                                    <Option value="Telus">Telus</Option>
                                    <Option value="Rogers">Rogers</Option>
                                    <Option value="Bell">Bell</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={<span className="text-white">How many Lines do you need?</span>}
                                name="lines"
                                rules={[{ required: true, message: 'Please input the number of lines you need!' }]}
                            >
                                <Input placeholder="Enter the number of lines" type="number" />
                            </Form.Item>

                            <Form.Item
                                label={<span className="text-white">How much spending on your monthly plan?</span>}
                                name="monthlySpend"
                                rules={[{ required: true, message: 'Please input your monthly spending!' }]}
                            >
                                <Input prefix="$" placeholder="Enter your monthly spending" type="number" />
                            </Form.Item>

                            <Form.Item
                                label={<span className="text-white">Which services can we assist you with?</span>}
                                name="services"
                                rules={[{ required: true, message: 'Please select at least one service!' }]}
                            >
                                <Checkbox.Group className="flex flex-wrap gap-4">
                                    <Checkbox value="Internet" className="text-white">Internet</Checkbox>
                                    <Checkbox value="Mobility" className="text-white">Mobility</Checkbox>
                                    <Checkbox value="Security" className="text-white">Security</Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                        </div>
                        <Form.Item
                            label={<span className="text-white">Additional Notes</span>}
                            name="additionalNotes"
                            className="col-span-1 sm:col-span-2"
                        >
                            <Input.TextArea placeholder="Enter additional notes or comments here" rows={4} />
                        </Form.Item>

                        <Form.Item>
                            <Button name='btnsubmit' type="primary" size='large' htmlType="submit" className="w-full">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div> */}
                <iframe src="https://boltwireless.ca/sendemail/index.html" className='w-full h-[1154px] md:h-[762px]' title='form iframe' frameborder="0"></iframe>
            </div>
            <div className="max-w-4xl mx-auto container px-4 sm:px-6 lg:px-8 overflow-hidden">
                <div className="flex flex-col md:flex-row md:justify-between items-center pb-5">
                    <div>
                        <img src={teluslogo} alt="" />
                    </div>
                    <div>
                        <p className='text-gray-700 font-bold'>
                            &copy; {currentYear}. All Rights Reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;