const padZero = (num) => (num < 10 ? '0' : '') + num;

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = padZero(now.getMonth() + 1); // Months are zero-based
    const day = padZero(now.getDate());
    const hours = padZero(now.getHours());
    const minutes = padZero(now.getMinutes());
    const seconds = padZero(now.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


export const getEmailTemplate = (emailData, orderNumber) => {

    const currentDate = getCurrentDateTime();
    const phoneNumber = emailData.customer_info.dayContactNo;
    const customerName = emailData.customer_info.firstName + " " + emailData.customer_info.lastName;
    const country = emailData.customer_info.country;
    const city = emailData.customer_info.city;
    const plan = emailData.device_details.plan;
    const deviceInfo = emailData.device_details;
    const subs_info = emailData.subscriber_info;
    const email = emailData.customer_info.email;
    const payment = emailData.device_details.payment;
    let gst = 0;

    if (subs_info.serviceProvince === "ontario") {
        gst = 12;
    }

    if (subs_info.serviceProvince === "alberta") {
        gst = 13;
    }

    if (subs_info.serviceProvince === "british columbia") {
        gst = 21;
    }

    return `
    <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Order Confirmation</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
        }

        .container {
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
            background-color: #fff;
            padding: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        .header,
        .footer {
            text-align: center;
            padding: 10px 0;
            border-top: 2px solid #2B8000;
        }

        .header {
            border-bottom: 2px solid #2B8000;
        }

        .header h1,
        .footer p {
            margin: 0;
        }

        .header h1 {
            font-size: 24px;
            color: #4B286D;
        }

        .footer p {
            font-size: 12px;
            color: #777;
        }

        .content {
            padding: 20px 0;
        }

        .content h2 {
            font-size: 18px;
            color: #4B286D;
            margin: 10px 0;
        }

        .content p {
            font-size: 14px;
            color: #555;
            line-height: 1.6;
        }

        .info-section {
            margin-bottom: 20px;
        }

        .info-section h2 {
            font-size: 18px;
            color: #4B286D;
            margin: 10px 0;
        }

        .info-section p {
            font-size: 14px;
            color: #555;
        }

        .info-section table {
            width: 100%;
            border-collapse: collapse;
        }

        .info-section th {
            background-color: #4B286D;
            color: #fff;
            padding: 10px;
            text-align: left;
            font-size: 14px;
            border-bottom: 2px solid #2B8000;
        }

        .info-section td {
            padding: 10px;
            border: 1px solid #ddd;
            font-size: 14px;
        }

        a {
            color: #2B8000;
        }
    </style>
</head>

<body>
    <div class="container">
        <div class="header">
            <h1>Thank You for Your Order</h1>
        </div>
        <div class="content">
            <p>Your order has been submitted successfully.</p>

            <div class="info-section">
                <h2>Order Processing</h2>
                <p>After we verify your order, you'll get an email within 1-3 business days letting you know that your order has been sent to TELUS for activation. A TELUS sales rep will then reach out to you to confirm your identity and, if you're a new customer, to check your credit. Your order will be completed and shipped in about 3-6 business days. If TELUS has backordered phones, they'll be activated in the order they were received.</p>

                <p>If you accidentally ordered a new phone number and you already have a TELUS number, or if you need to make changes to your order, please contact us within 24 hours and provide your order number for help.</p>
                
                <p>For orders that need a phone number transfer, you'll get a temporary number until your new device arrives. You'll find instructions for completing the number transfer with your new phone. Please note that transferring numbers from landlines, Koodo, or Public Mobile may cause a short service disruption.</p>
                
                <p>If you ordered a new phone number, TELUS will give you a number based on the city you chose when placing your order.</p>
                
            </div>

            <div class="info-section">
                <h2>Support</h2>
                <p>Email: <a href="mailto:info@boltwireless.ca">info@boltwireless.ca</a> or call 778 957 8000.</p>
                <p>Mon to Fri: 9:00am to 5:00pm.</p>
                <p>Thank you for your business.</p>
                <p>Boltwireless Team</p>
            </div>

            <div class="info-section">
                <h2>Order Information</h2>
                <table>
                    <tr>
                        <th>Order Date:</th>
                        <td>${currentDate}</td>
                    </tr>
                    <tr>
                        <th>Order Number:</th>
                        <td>00000${orderNumber}</td>
                    </tr>
                    <tr>
                        <th>Order Status:</th>
                        <td>Pending</td>
                    </tr>
                </table>
            </div>

           

            <div class="info-section">
                <h2>Customer Information</h2>
                <table>
                    <tr>
                        <th>Phone Number:</th>
                        <td>${phoneNumber}</td>
                    </tr>
                    <tr>
                        <th>Customer Name:</th>
                        <td>${customerName}</td>
                    </tr>
                    <tr>
                        <th>Country:</th>
                        <td>${country}</td>
                    </tr>
                    <tr>
                        <th>City:</th>
                        <td>${city}</td>
                    </tr>
                    <tr>
                        <th>Customer Email:</th>
                        <td>${email}</td>
                    </tr>
                </table>
            </div>

            <div class="info-section">
            <h2>Plan Information</h2>
            <table>
                <tr>
                    <th>Name</th>
                    <td>${plan.title}</td>
                </tr>
                <tr>
                    <th>Details:</th>
                    <td>${plan.details}</td>
                </tr>
                <tr>
                    <th>Eligible:</th>
                    <td>${plan.eligible}</td>
                </tr>
                <tr>
                    <th>Speed:</th>
                    <td>${plan.speed}</td>
                </tr>
                <tr>
                    <th>Data:</th>
                    <td>${plan.data}</td>
                </tr>
                <tr>
                    <th>Price:</th>
                    <td>$${plan.price}</td>
                </tr>
            </table>
        </div>

           

            <div class="info-section">
                <h2>Subscriber Details</h2>
                <table>
                    <tr>
                        <th class="hide_show">Device Name</th>
                        <th>User Name</th>
                        <th>City</th>
                        <th>Province</th>
                    </tr>
                    <tr>
                        <td class="hide_show">${deviceInfo.device_name} / ${deviceInfo.color} / ${deviceInfo.storage}</td>
                        <td>${subs_info.firstName}</td>
                        <td>${subs_info.serviceCity}</td>
                        <td>${subs_info.serviceProvince}</td>
                    </tr>
                </table>
            </div>

            <div class="info-section">
            <h2>Payment Details</h2>
            <table>
                <tr class="hide_show">
                    <th>Payment Type</th>
                    <td id="paymentName">${payment.name}</td>
                </tr>
                <tr>
                    <th>GST</th>
                    <td>${gst}%</td>
                </tr>
                <tr id="payment_type" class="hide_show">
                    <th>Device Price:</th>
                    <td>$${payment.price} + GST = $${(((+gst / 100) * +payment.price) + +payment.price).toFixed(4)} /mon</td>
                </tr>
                <tr>
                    <th>Plan Price:</th>
                    <td>$${plan.price} /mon</td>
                </tr>
                <tr>
                    <th>Total Amount</th>
                    <td>$${((((+gst / 100) * +payment.price) + +payment.price) + +plan.price).toFixed(4)} /mon</td>
                </tr>
            </table>
        </div>

        </div>
        <div class="footer">
            <p>&copy; 2024 Boltwireless. All rights reserved.</p>
        </div>
    </div>

    <script>
        let payment = document.getElementById("paymentName").innerHTML;
        if (payment == 0) {
            // Select all elements with the class name "hide_show"
            const elements = document.querySelectorAll('.hide_show');
            
            // Iterate over the selected elements and set their display property to "none"
            elements.forEach(element => {
                element.style.display = 'none';
            });
        } else {
            // If payment is not 0, set the display property to "block"
            const elements = document.querySelectorAll('.hide_show');
            
            elements.forEach(element => {
                element.style.display = 'initial';
            });
        }
    </script>
</body>

</html>
    
    `
}