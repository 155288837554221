import React, { useState } from 'react';
import { Button, Checkbox, DatePicker, Form, Input, Select, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import usePost from "../../hooks/usePost";
import dayjs from 'dayjs';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';
import { setActiveProduct, setCart, setCustomerInfo } from '../../store/features/cart/cartSlice';
import Footer from '../../components/footer/Footer';
import { getEmailTemplate } from '../../utils/functions';
import { setProvinceTax } from '../../store/features/user/userSlice';

const { Option } = Select;

const SubInfoForm = () => {

    const [useBilling, setUseBilling] = useState(false);
    const { customerInfo, cart } = useSelector(state => state.cart);
    const { post, loading } = usePost();
    const [form] = useForm();
    const navigate = useNavigate();
    const [selectedIdType, setSelectedIdType] = useState(null);
    const tax = useSelector(state => state.user.provinceTax);
    const dispatch = useDispatch();

    const onFinish = async (values) => {
        const formData = { ...values, dateOfBirth: dayjs(values.dateOfBirth).format('YYYY-MM-DD') };
        let payLoadData;
        if (useBilling) {
            const formFields = {
                ...formData,
                streetName_ship: formData.streetName,
                streetNo_ship: formData.streetNo,
                streetNoSuffix_ship: formData.streetNoSuffix,
                streetType_ship: formData.streetType,
                streetDirection_ship: formData.streetDirection,
                floorNo_ship: formData.floorNo,
                unit_ship: formData.unit,
                buzzarNo_ship: formData.buzzarNo,
                city_ship: formData.city,
                province_ship: formData.province,
                country_ship: formData.country,
                postalCode_ship: formData.postalCode,
                dayContactNo_ship: formData.dayContactNo,
                dayExitContactNo_ship: formData.dayExitContactNo,
                attention_ship: formData.attention,
            }
            const payload = {
                customer_info: { ...formFields, byod: cart.byod },
                subscriber_info: customerInfo,
                device_details: cart
            }
            payLoadData = payload;
        } else {
            const payload = {
                customer_info: formData,
                subscriber_info: customerInfo,
                device_details: cart
            }
            payLoadData = payload;
        }



        // api call here to

        try {
            const data = await post("/addorder.php", payLoadData);
            if (data.success) {
                const emailHtmlTemplate = getEmailTemplate(payLoadData, data.order_number);
                await post("/addorderemail.php", {
                    order_id: data.order_number,
                    email: payLoadData.customer_info.email,
                    email_template: emailHtmlTemplate,
                })
                dispatch(setCustomerInfo(null));
                dispatch(setCart(null));
                dispatch(setActiveProduct(null));
                message.success("Order Placed Successfully");
                form.resetFields();
                navigate(`/orderconfirm/${data.order_number}`);

            } else {
                message.error("Error while placing order");
            }

        } catch (error) {
            console.log(error);
        }

    };


    const onAddressChnage = (e) => {
        setUseBilling(!useBilling);
    }

    const handleProvince = (value) => {
        dispatch(setProvinceTax(value));
    }

    const billingStreetTypeOptions = ["Abbey", "Acres", "Allé", "Alley", "Autoroute", "Avenue", "Bay", "Beach", "Bend", "Boulevard", "By-pass", "Byway", "Campus", "Cape", "Carré", "Carrefour", "Centre", "Cercle", "Chase", "Chemin", "Circle", "Circuit", "Close", "Common", "Concession", "Corners", "Côte", "Cour", "Cours", "Court", "Cove", "Crescent", "Croissant", "Crossing", "Cul-de-sac", "Dale", "Dell", "Diversion", "Downs", "Drive", "Échangeur", "End", "Esplanade", "Estates", "Expressway", "Extension", "Farm", "Field", "Forest", "Freeway", "Front", "Gardens", "Gate", "Glade", "Glen", "Green", "Grounds", "Grove", "Harbour", "Heath", "Heights", "Highlands", "Highway", "Hill", "Hollow", "Île", "Impasse", "Inlet", "Island", "Key", "Knoll", "Landing", "Lane", "Limits", "Line", "Link", "Lookout", "Loop", "Mall", "Manor", "Maze", "Meadow", "Mews", "Montéé", "Moor", "Mount", "Mountain", "Orchard", "Parade", "Parc", "Park", "Parkway", "Passage", "Path", "Pathway", "Pines", "Place", "Plateau", "Plaza", "Point", "Pointe", "Port", "Private", "Promenade", "Quai", "Quay", "Ramp", "Rang", "Range", "Ridge", "Rise", "Road", "Rond-point", "Route", "Row", "Rue", "Ruelle", "Run", "Sentier", "Square", "Street", "Subdivision", "Terrace", "Terrasse", "Thicket", "Towers", "Townline", "Trail", "Turnabout", "Vale", "Via", "View", "Village", "Villas", "Vista", "Voie", "Walk", "Way", "Wharf", "Wood", "Wynd", "Other"];
    const billingStreetDirOptions = ["E - East", "N - North", "NE - Northeast", "NW - Northwest", "S - South", "SE - Southeast", "SW - Southwest", "W - West"];
    const streetSuffixes = ["1/4", "1/2", "3/4", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    // const billingProvStateOptions = ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland", "Northwest Territories", "Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Yukon"];


    const handleIdTypeChange = (value) => {
        setSelectedIdType(value);
    };


    return (
        <>
            <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 py-8 bg-white shadow-sm rounded-lg overflow-hidden ">
                <Form
                    name="subscriber_form"
                    initialValues={{
                        remember: true,
                        country: 'Canada',
                    }}
                    onFinish={onFinish}
                    layout="vertical"
                    form={form}
                >
                    <h2 className='text-[18px] sm:text-[22px] text-themeColor font-light'>Subscriber Information</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 my-4 gap-4">
                        <div className='p-3 border border-gray-300 shadow-sm rounded-lg'>
                            <h3 className='font-medium text-[16px] mb-4'>Customer Information</h3>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>First Name</label>
                                <Form.Item
                                    name="firstName"
                                    rules={[{ required: true, message: 'Please input your first name!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Last Name</label>
                                <Form.Item
                                    name="lastName"
                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Email Address</label>
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: 'Please input your email!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Workplace or Organization</label>
                                <Form.Item
                                    name="workspace"
                                    rules={[{ required: true, message: 'Please input your workspace!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input className='p-[2px]' />
                                </Form.Item>
                            </div>

                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Are you 18+</label>
                                <Form.Item
                                    name="isAdult"
                                    rules={[{ required: true, message: 'Please input your first name!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Select className='py-[2px]' >
                                        <Option value="yes">Yes</Option>
                                        <Option value="no">No</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>

                        <div className='p-3 border border-gray-300 shadow-sm rounded-lg'>
                            <h3 className='font-medium text-[16px] mb-4'>Personal Information</h3>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Date of Birth</label>
                                <Form.Item
                                    name="dateOfBirth"
                                    rules={[{ required: true, message: 'Please input your DOB' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <DatePicker className='py-[2px] w-full' />
                                </Form.Item>
                            </div>

                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Credit Card No</label>
                                <Form.Item
                                    name="creditCardNo"
                                    rules={[{ required: true, message: 'Please input your card no' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input.Password
                                        className='p-[2px] px-2'

                                    />
                                </Form.Item>
                            </div>

                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Card Expiration</label>
                                <div className="col-span-3 md:col-span-2">
                                    <div className="flex gap-3">
                                        <Form.Item
                                            name="cartExpMonth"
                                            rules={[{ required: true, message: 'Expiration Month' }]}
                                            className='mb-0 w-[50%]'
                                        >
                                            <Input type='number' className='p-[2px] px-3' placeholder='Expiration Month' />
                                        </Form.Item>
                                        <Form.Item
                                            name="cardExpYear"
                                            rules={[{ required: true, message: 'Expiration Year' }]}
                                            className='mb-0 w-[50%]'
                                        >
                                            <Input type='number' className='p-[2px] px-3' placeholder='Expiration Year' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <h3 className='font-medium text-[16px] my-2'>Please provide either Driver's Licence or SIN</h3>

                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Identification Type</label>
                                <Form.Item
                                    name="identity"
                                    rules={[{ required: true, message: 'Please select your identification type!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Select className='py-[2px]' onChange={handleIdTypeChange}>
                                        <Option value="driver licence">Driver Licence</Option>
                                        <Option value="social licence">Social Licence</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            {(selectedIdType === 'driver licence' || selectedIdType === 'social licence') && (
                                <div className="grid grid-cols-3 items-center mb-1">
                                    <label className='col-span-3 md:col-span-1 font-light text-[13px]'>{selectedIdType === 'driver licence' ? 'Driver Licence' : 'Social Licence'} Number</label>
                                    <Form.Item
                                        name="id_number"
                                        rules={[{ required: true, message: `Please input your ${selectedIdType === 'driver licence' ? 'driver licence' : 'social licence'} number!` }]}
                                        className='mb-0 col-span-3 md:col-span-2'
                                    >
                                        <Input.Password className='py-[2px]' />
                                    </Form.Item>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 my-4 gap-4">
                        <div className='p-3 border border-gray-300 shadow-sm rounded-lg'>
                            <h3 className='font-medium text-[16px] mb-4'>Billing Address</h3>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Street Number</label>
                                <Form.Item
                                    name="streetNo"
                                    rules={[{ required: true, message: 'Please input your street no!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input type='number' className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Street Number Suffix</label>
                                <Form.Item
                                    name="streetNoSuffix"
                                    rules={[{ required: true, message: 'Please input your street suffix!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Select>
                                        {streetSuffixes.map((item, index) => (
                                            <Option key={index + " " + item} value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Street Name</label>
                                <Form.Item
                                    name="streetName"
                                    rules={[{ required: true, message: 'Please input your street name!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Street Type</label>
                                <Form.Item
                                    name="streetType"
                                    rules={[{ required: true, message: 'Please choose your street type!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Select>
                                        {billingStreetTypeOptions.map((item, index) => (
                                            <Option key={index + " " + item} value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Street Direction</label>
                                <Form.Item
                                    name="streetDirection"
                                    rules={[{ required: true, message: 'Please choose your street Direction!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Select>
                                        {billingStreetDirOptions.map((item, index) => (
                                            <Option key={index + " " + item} value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Floor Number</label>
                                <Form.Item
                                    name="floorNo"
                                    rules={[{ required: true, message: 'Please input your floor no!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input type='number' className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Unit/Suite/Apt</label>
                                <Form.Item
                                    name="unit"
                                    rules={[{ required: true, message: 'Please input your Unit/Suite?Apt!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Buzzer Number</label>
                                <Form.Item
                                    name="buzzarNo"
                                    rules={[{ required: true, message: 'Please input your buzzar no!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input type='number' className='p-[2px]' />
                                </Form.Item>
                            </div>

                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>City</label>
                                <Form.Item
                                    name="city"
                                    rules={[{ required: true, message: 'Please input your city!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Province</label>
                                <Form.Item
                                    name="province"
                                    rules={[{ required: true, message: 'Please input choose Provnce!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Select defaultValue={tax} placeholder="Select Province" onChange={handleProvince} className='w-full'>
                                        <Option value="13">Alberta</Option>
                                        <Option value="12">Ontario</Option>
                                        <Option value="21">British Columbia</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Country</label>
                                <Form.Item
                                    name="country"
                                    rules={[{ required: true, message: 'Please input choose country!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Select>
                                        <Option value="canada">Canada</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Postal Code</label>
                                <Form.Item
                                    name="postalCode"
                                    rules={[{ required: true, message: 'Please input your postal code!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Daytime Contact No</label>
                                <Form.Item
                                    name="dayContactNo"
                                    rules={[{ required: true, message: 'Please input your contact no!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input type='number' className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Daytime Contact No Exit</label>
                                <Form.Item
                                    name="dayExitContactNo"
                                    rules={[{ required: true, message: 'Please input your contact no exit!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input type='number' className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Attention</label>
                                <Form.Item
                                    name="attention"
                                    rules={[{ required: true, message: 'Please input your contact no!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                >
                                    <Input className='p-[2px]' />
                                </Form.Item>
                            </div>
                        </div>

                        <div className='p-3 border border-gray-300 shadow-sm rounded-lg'>
                            <h3 className='font-medium text-[16px] mb-4'>Shipping Address</h3>
                            <Checkbox className='mb-3' onChange={onAddressChnage}>User Billing Address</Checkbox>

                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Street Number</label>
                                <Form.Item
                                    name="streetNo_ship"
                                    rules={[{ required: !useBilling, message: 'Please input your street no!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Input disabled={useBilling} type='number' className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Street Number Suffix</label>
                                <Form.Item
                                    name="streetNoSuffix_ship"
                                    rules={[{ required: !useBilling, message: 'Please input your street suffix!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Select disabled={useBilling}>
                                        {streetSuffixes.map((item, index) => (
                                            <Option key={index + " " + item} value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Street Name</label>
                                <Form.Item
                                    name="streetName_ship"
                                    rules={[{ required: !useBilling, message: 'Please input your street name!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Input disabled={useBilling} className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Street Type</label>
                                <Form.Item
                                    name="streetType_ship"
                                    rules={[{ required: !useBilling, message: 'Please choose your street type!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Select disabled={useBilling}>
                                        {billingStreetTypeOptions.map((item, index) => (
                                            <Option key={index + " " + item} value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Street Direction</label>
                                <Form.Item
                                    name="streetDirection_ship"
                                    rules={[{ required: !useBilling, message: 'Please choose your street Direction!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Select disabled={useBilling}>
                                        {billingStreetDirOptions.map((item, index) => (
                                            <Option key={index + " " + item} value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Floor Number</label>
                                <Form.Item
                                    name="floorNo_ship"
                                    rules={[{ required: !useBilling, message: 'Please input your floor no!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Input disabled={useBilling} type='number' className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Unit/Suite/Apt</label>
                                <Form.Item
                                    name="unit_ship"
                                    rules={[{ required: !useBilling, message: 'Please input your Unit/Suite?Apt!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Input disabled={useBilling} className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Buzzer Number</label>
                                <Form.Item
                                    name="buzzarNo_ship"
                                    rules={[{ required: !useBilling, message: 'Please input your buzzar no!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Input disabled={useBilling} type='number' className='p-[2px]' />
                                </Form.Item>
                            </div>

                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>City</label>
                                <Form.Item
                                    name="city_ship"
                                    rules={[{ required: !useBilling, message: 'Please input your city!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Input disabled={useBilling} className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Province</label>
                                <Form.Item
                                    name="province_ship"
                                    rules={[{ required: !useBilling, message: 'Please input choose Provnce!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Select defaultValue={tax} placeholder="Select Province" onChange={handleProvince} className='w-full'>
                                        <Option value="13">Alberta</Option>
                                        <Option value="12">Ontario</Option>
                                        <Option value="21">British Columbia</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Country</label>
                                <Form.Item
                                    name="country_ship"
                                    rules={[{ required: !useBilling, message: 'Please input choose country!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Select disabled={useBilling}>
                                        <Option value="canada">Canada</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Postal Code</label>
                                <Form.Item
                                    name="postalCode_ship"
                                    rules={[{ required: !useBilling, message: 'Please input your postal code!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Input disabled={useBilling} className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Daytime Contact No</label>
                                <Form.Item
                                    name="dayContactNo_ship"
                                    rules={[{ required: !useBilling, message: 'Please input your contact no!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Input disabled={useBilling} type='number' className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Daytime Contact No Exit</label>
                                <Form.Item
                                    name="dayExitContactNo_ship"
                                    rules={[{ required: !useBilling, message: 'Please input your contact no exit!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Input disabled={useBilling} type='number' className='p-[2px]' />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 items-center mb-1">
                                <label className='col-span-3 md:col-span-1 font-light text-[13px]'>Attention</label>
                                <Form.Item
                                    name="attention_ship"
                                    rules={[{ required: !useBilling, message: 'Please input your contact no!' }]}
                                    className='mb-0 col-span-3 md:col-span-2'
                                    disabled={useBilling}

                                >
                                    <Input disabled={useBilling} className='p-[2px]' />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full justify-end">
                        <Button loading={loading} disabled={loading} type='primary' className='disabled:text-themeColor disabled:opacity-100 text-[18px] h-auto' htmlType='submit'>Place Order</Button>
                    </div>
                </Form>
            </div>
            <Footer />
        </>
    )
}

export default SubInfoForm;
