import React from 'react';
import logo from "../../assets/image/logo.webp"
import { Link } from 'react-router-dom';
import { FaFacebook, FaYoutube, FaInstagram, FaLinkedin, FaWhatsappSquare } from "react-icons/fa";
import { FaXTwitter, FaTiktok } from "react-icons/fa6";

const Footer = () => {
    return (
        <footer className="bg-[#F3F2F9] pb-8 pt-12">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col items-center text-center">
                    <img src={logo} alt="boltwireless" className='w-[130px]' />
                    <p className='mt-4 font-light'>Bolt Wireless © has become the go-to destination for all communication needs in Canada. Recognized for exceptional customer service and cutting-edge solutions that enhance people’s mobile lives and businesses. Now servicing in 11 Areas nationwide!</p>
                    <ul className='flex gap-6 my-4'>
                        <li><Link to="https://www.facebook.com/Telusstores/" target='blank'><FaFacebook className='text-[24px] text-themeColor' /></Link></li>
                        <li><Link to="https://twitter.com/Boltwirelessinc" target='blank'><FaXTwitter className='text-[24px] text-themeColor' /></Link></li>
                        <li><Link to="https://www.youtube.com/channel/UCGHonfW8q63Kcsf2N8COw3A" target='blank'><FaYoutube className='text-[24px] text-themeColor' /></Link></li>
                        <li><Link to="https://www.instagram.com/bolt_wireless/" target='blank'><FaInstagram className='text-[24px] text-themeColor' /></Link></li>
                        <li><Link to="https://www.tiktok.com/@boltwirelessinc" target='blank'><FaTiktok className='text-[24px] text-themeColor' /></Link></li>
                        <li><Link to="https://www.linkedin.com/company/bolt-wireless/" target='blank'><FaLinkedin className='text-[24px] text-themeColor' /></Link></li>
                        <li><Link to="https://api.whatsapp.com/send/?phone=17789578000&text&app_absent=0" target='blank'><FaWhatsappSquare className='text-[24px] text-themeColor' /></Link></li>
                    </ul>
                    <ul className='flex gap-5 mt-4'>
                        <li className='mb-2 hover:text-themeColor'><Link to={"https://boltwireless.ca/about-us/"} target='_blank'>About Us</Link></li>
                        <li className='mb-2 hover:text-themeColor'><Link to={"https://boltwireless.ca/contact-us/"} target="_blank">Contact Us</Link></li>
                        <li className='mb-2 hover:text-themeColor'><Link to={"/"}>Products</Link></li>
                    </ul>
                </div>
                <hr className="my-6 border-themeColor sm:mx-auto dark:border-gray-700 lg:my-8" />
                <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© {new Date().getFullYear()} <a href="https://boltwireless.ca/" target='black' className="hover:underline">Boltwireless™</a>. All Rights Reserved.</span>
            </div>
        </footer >
    );
};

export default Footer;
