import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import ProductList from './screens/productList/ProductList';
import EligibleForm from './screens/eligibleForm/EligibleForm';
import SingleProduct from './components/singleProduct/SingleProduct';
import NotFound from './components/notFound/NotFound';
import SubscriberInfo from './screens/subscriberInfo/SubscriberInfo';
import SubInfoForm from './screens/subInfoForm/SubInfoForm';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAllProducts } from './store/features/products/productSlice';
import useGet from './hooks/useGet';
import Admin from './screens/admin/Admin';
import OrderDetail from './screens/orderDetails/OrderDetail';
import Login from './screens/login/Login';
import OrderConfirm from './components/orderConfirm/OrderConfirm';
import LandingPage from './screens/landingPage/LandingPage';
import LatestProducts from './screens/latestProducts/LatestProducts';
import ThankYou from './screens/thankYou/ThankYou';

// ScrollToTop component to reset scroll position on route change
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    // Cleanup function to reset scroll position when component unmounts
    return () => {
      window.scrollTo(0, 0);
    };
  }, [pathname]);

  return null;
};

function App() {

  const dispatch = useDispatch();
  const { data: products } = useGet("/getallphones.php?tokenz=hamza@123");
  const { activeProduct, cart, customerInfo, activeOrder } = useSelector(state => state.cart);
  const { currentUser } = useSelector(state => state.user);

  useEffect(() => {
    if (products) {
      const preprocessedProducts = products.map(product => {
        if (product.devicename_new && product.devicename_new.split(" ")[0] === "Moto") {
          return { ...product, devicename_new: "Motorola " + product.devicename_new };
        }
        return product;
      });

      dispatch(setAllProducts(preprocessedProducts));
    }
  }, [dispatch, products]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={currentUser ? <ProductList /> : <Login />}></Route>
        <Route path='/eligibleform' element={<EligibleForm />}></Route>
        <Route path='/productlist' element={currentUser ? <ProductList /> : <Navigate to="/" />}></Route>
        {activeProduct && <Route path='/singleproduct' element={currentUser ? <SingleProduct /> : <Navigate to="/" />}></Route>}
        {cart && <Route path='/subscriberinfo' element={<SubscriberInfo />} ></Route>}
        {customerInfo && <Route path='/subinfoform' element={<SubInfoForm />}></Route>}
        <Route path='/orderconfirm/:id' element={<OrderConfirm />}> </Route>
        <Route path='/adminpanelkey4857845784jdk483' element={<Admin />}></Route>
        <Route path='/orderdetail' element={activeOrder ? <OrderDetail /> : <Navigate to="/adminpanelkey4857845784jdk483" />}></Route>
        <Route path='/telusemployeeofferprogram' element={<LandingPage />}></Route>
        <Route path='/latestproducts' element={<LatestProducts />}></Route>
        <Route path='/thank-you' element={<ThankYou />}></Route>
        <Route path='/*' element={<NotFound />}></Route>
      </Routes>
    </>
  );
}

export default App;
