import React from 'react';
import { Tabs, Form, Input, Button, message } from 'antd';
import usePost from '../../hooks/usePost';
import { useForm } from 'antd/es/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser, setIsGuest } from '../../store/features/user/userSlice';
import { setCustomerInfo } from '../../store/features/cart/cartSlice';
import { useNavigate } from 'react-router-dom';

const AuthModalView = ({ handleCancel, isPort, values }) => {
    const [loginForm] = useForm();
    const [registerForm] = useForm();
    const { currentUser } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { post: registerPost, loading: registerLoading } = usePost();
    const { post: loginPost, loading: loginLoading } = usePost();


    const addToCart = () => {
        if (isPort) {
            dispatch(setCustomerInfo({ ...values, isPorting: isPort }));
        } else {
            dispatch(setCustomerInfo({ ...values, isPorting: isPort, cellno: "", serviceprovider: "" }));
        }
        navigate("/subinfoform");
    }

    const loginUser = async (values) => {
        try {
            const resp = await loginPost("/loginx.php", values);
            if (resp && resp.status === "success") {
                dispatch(setCurrentUser(resp[0]));
                message.success("Logged In Successfully");
                handleCancel();
                addToCart();
            } else {
                message.error("Invalid Credentials");
            }
        } catch (error) {
            console.error("Login error:", error);
            message.error("Something went wrong");
        }
    };

    const signupUser = async (values) => {
        try {
            const resp = await registerPost("/register.php", values);
            if (resp) {
                dispatch(setCurrentUser({ ...currentUser, password: values.password }));
                registerForm.resetFields();
                handleCancel();
                addToCart();
            }
        } catch (error) {
            console.error("Signup error:", error);
            message.error("Something went wrong");
        }
    }

    const guestLogin = () => {
        dispatch(setIsGuest(true));
        handleCancel();
        addToCart();
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const tabItems = [
        {
            key: "1",
            label: "Login",
            children: (
                <Form
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={loginUser}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={loginForm}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input placeholder="Email" autoComplete="email" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password placeholder="Password" autoComplete="current-password" />
                    </Form.Item>

                    <Form.Item>
                        <Button loading={loginLoading} type="primary" htmlType="submit" className="w-full">
                            Login
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={guestLogin} type="default" className="w-full">
                            Go Guest
                        </Button>
                    </Form.Item>
                </Form>
            ),
        },
        {
            key: "2",
            label: "Set Your Password",
            children: (
                <Form
                    name="signup"
                    initialValues={{ email: currentUser && currentUser.email, remember: true }}
                    onFinish={signupUser}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={registerForm}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input placeholder="Email" readOnly autoComplete="email" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password placeholder="Password" autoComplete="new-password" />
                    </Form.Item>

                    <Form.Item>
                        <Button loading={registerLoading} type="primary" htmlType="submit" className="w-full">
                            Set Password
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={guestLogin} type="default" className="w-full">
                            Go Guest
                        </Button>
                    </Form.Item>
                </Form>
            ),
        },
    ];

    return (
        <div className="max-w-md mx-auto bg-white">
            <Tabs defaultActiveKey="2" centered size='large' items={tabItems} />
        </div>
    );
};

export default AuthModalView;
