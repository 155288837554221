import React from 'react'
import { useNavigate } from 'react-router-dom'

const ThankYou = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-[#4B286D] h-screen flex items-center justify-center flex-col text-white">
            <h1 className="text-5xl font-extrabold mb-5">Thank You for Reaching Out!</h1>
            <p className="text-2xl mb-8">Your submission was successful. We appreciate your interest and will get in touch with you shortly.</p>
            <button
                onClick={() => navigate("/telusemployeeofferprogram")}
                className="bg-[#75BD43] text-white py-3 px-6 text-xl font-medium rounded-lg transition-colors duration-300 hover:bg-[#6AA839]">
                Back to Home
            </button>
        </div>
    )
}

export default ThankYou
