import React from 'react'
import { Link, useParams } from 'react-router-dom'
import logo from "../../assets/image/logo.webp"

const OrderConfirm = () => {
    const { id } = useParams();

    return (
        <div className='flex justify-center items-center h-screen'>
            <div className='p-[30px] border border-gray-300 shadow-lg'>
                <div className="text-center py-5">
                    <div className="flex justify-center mb-4">
                        <img src={logo} alt="boltwireless" width="120px" />
                    </div>
                    <h2 className='text-gray-800 text-[25px] font-medium'>
                        Thank You For Your Order! Your Order number is <br></br> #00000{id}
                    </h2>
                    <p className='font-light my-2'>If you do not recieved the confirmation email <br></br> please check your Junk-mail folder</p>
                    <div className="flex justify-center mt-4">
                        <Link to={"/"} className='bg-themeGreen text-white px-4 py-2 rounded-full uppercase hover:bg-themeColor duration-300'>
                            Continue Shopping
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderConfirm
