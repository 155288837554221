import React, { useEffect, useState } from 'react';
import telusLogo from "../../assets/image/telus.jpg";
import telusSign from "../../assets/image/telussign.png";
import { Button, Checkbox, Form, Input, Modal, Select, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerInfo } from '../../store/features/cart/cartSlice';
import { useNavigate } from 'react-router-dom';
import AuthModalView from '../../components/authModal/AuthModalView';
import Footer from '../../components/footer/Footer';
import { setProvinceTax } from '../../store/features/user/userSlice';

const { Option } = Select;

const SubscriberInfo = () => {

    const [isPort, setIsPort] = useState(false);
    const { cart } = useSelector(state => state.cart);
    const tax = useSelector(state => state.user.provinceTax);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentUser } = useSelector(state => state.user);
    const [formData, setFormData] = useState();
    const [form] = Form.useForm();
    const planPrice = cart.plan.price;
    const planCategory = cart.plan.details;
    const planTitle = cart.plan.title;
    const planSpeed = cart.plan.speed;
    const planData = cart.plan.data;
    const planBonus = cart.plan.bonus;
    const planEligibility = cart.plan.eligible;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        if (!tax) {
            message.error("Please Select Province");
            return;
        }

        setFormData(values);

        if (currentUser && !currentUser.password) {
            showModal();
        } else {
            if (isPort) {
                dispatch(setCustomerInfo({ ...values, isPorting: isPort }));
            } else {
                dispatch(setCustomerInfo({ ...values, isPorting: isPort, cellno: "", serviceprovider: "" }));
            }
            navigate("/subinfoform");
        }

    };

    const onNumberPortChange = (e) => {
        setIsPort(!isPort);
    }

    const handleProvince = (value) => {
        dispatch(setProvinceTax(value));
    }

    useEffect(() => {
        // Set the selected province in the bottom Select when it changes
        form.setFieldsValue({ serviceProvince: tax });
    }, [tax, form]);

    return (
        <>
            <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 py-8 bg-white shadow-sm rounded-lg overflow-hidden ">
                <h2 className='text-[18px] sm:text-[22px] text-themeColor font-light'>Subscriber Information</h2>

                <div className='my-4 flex gap-4 items-center'>
                    <h2 className='font-medium'>Select Province</h2>
                    <Select defaultValue={tax} placeholder="Select Province" onChange={handleProvince} className='w-[120px] sm:w-[200px]'>
                        <Option value="13">Alberta</Option>
                        <Option value="12">Ontario</Option>
                        <Option value="21">British Columbia</Option>
                    </Select>
                </div>

                <div className={`grid grid-cols-1 ${cart.byod ? "md:grid-cols-1" : "md:grid-cols-2"} my-4 gap-4`}>
                    {!cart.byod &&
                        <div className='p-3 border border-gray-300 shadow-sm rounded-lg'>
                            <div className="grid grid-cols-12 gap-6">
                                <div className='col-span-6 sm:col-span-3 grid justify-center'>
                                    <img src={cart.image} alt={cart.device_name} className='w-[100%]' />
                                </div>
                                <div className='col-span-6 sm:col-span-3'>
                                    <div className="border-b border-gray-300 text-center py-1">
                                        <h3 className='text-[18px] font-medium'>$0.00</h3>
                                        <p className='text-[12px] font-light'>Upfront</p>
                                    </div>
                                    <div className="border-b border-gray-300 text-center py-1">
                                        <h3 className='text-[18px] font-medium'>${cart.payment.price}</h3>
                                        <p className='text-[12px] font-light'>Monthly</p>
                                    </div>
                                    <div className="border-b border-gray-300 text-center py-1">
                                        <p className='text-[12px] font-light'>2 Year Term</p>
                                    </div>
                                </div>
                                <div className='col-span-12 sm:col-span-6'>
                                    <div className="border-b border-gray-300 text-center py-1">
                                        <p className='text-[14px] font-light'>TELUS</p>
                                    </div>
                                    <div className="border-b border-gray-300 text-center py-1">
                                        <p className='text-[14px] font-light'>Activate New Service</p>
                                    </div>
                                    <div className="border-b border-gray-300 text-center py-1">
                                        <p className='text-[14px] font-light'>Zero Upfront</p>
                                    </div>
                                    <div className="border-b border-gray-300 text-center py-1">
                                        <p className='text-[14px] font-light'>Colour : {cart.color}</p>
                                    </div>
                                    <div className="border-b border-gray-300 text-center py-1">
                                        <p className='text-[14px] '>{tax ? `Sales Tax ${tax}%` : "Select Province For Tax"}</p>
                                    </div>
                                    <div className="border-b border-gray-300 text-center py-1">
                                        <p className='text-[14px] '>Total Device payment with tax $58.05</p>
                                    </div>
                                </div>
                            </div>
                            <div className='py-2 px-3'>
                                <strong className='text-sm'>{cart.brand}</strong>
                                <h3 className='text-[14px] sm:text-[17px] font-light'>{cart.device_name}</h3>
                            </div>
                            <div className="pb-2">
                                {/* <h3 className='font-medium text-[14px]'>Add-ons: One Time Connection Fee</h3> */}
                                <div className="flex justify-between mt-2 items-center pr-4">
                                    <div className='flex gap-4'>
                                        <img src={telusLogo} alt="tel us" className='w-20' />
                                    </div>
                                    <div className='text-right'>
                                        <p className='text-[14px] text-black font-light'>Monthly Mobile Payment : ${cart.payment.price}/mon</p>
                                        <p className='text-[14px] text-black font-light'>After Tax Payment : ${(((+tax / 100) * +cart.payment.price) + +cart.payment.price).toFixed(4)}/mon</p>
                                        <p className='text-[14px] text-black font-light'>Plan Payment : ${planPrice}/mon</p>
                                        <p className='text-[14px] text-black font-light'>Total Payment : ${(+planPrice + (((+tax / 100) * +cart.payment.price) + +cart.payment.price) + +cart.payment.price).toFixed(4)}/mon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='p-3 border border-gray-300 shadow-sm rounded-lg'>
                        <div className="flex gap-4 pb-3 border-b border-gray-300">
                            <img src={telusSign} alt="tel us sign" />
                            <h3 className='text-[25px] font-medium'>${planPrice}</h3>
                        </div>
                        <div className='my-4'>
                            <p className='text-[16px] mb-[3px]'><span className='font-medium'>Plan :</span> {planTitle}</p>
                            <p className='text-[16px] mb-[3px]'><span className='font-medium'>Cateogry :</span> {planCategory}</p>
                            <p className='text-[16px] mb-[3px]'><span className='font-medium'>Data :</span> {planData}</p>
                            <p className='text-[16px] mb-[3px]'><span className='font-medium'>Speed :</span> {planSpeed}</p>
                            <p className='text-[16px] mb-[3px]'><span className='font-medium'>Bonus :</span> {planBonus}</p>
                            <p className='text-[16px] mb-[3px]'><span className='font-medium'>Eligibility :</span> {planEligibility}</p>
                        </div>
                    </div>
                </div>
                <Form
                    name="subscriber_form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    layout="vertical"
                    form={form}
                >
                    <div className='grid grid-cols-1 md:grid-cols-2 my-4 gap-4'>
                        <div className='p-3 border border-gray-300 shadow-sm rounded-lg px-6'>
                            <h3 className='font-medium text-[18px] mb-4'>Subscriber Information</h3>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <Form.Item
                                    label="First Name"
                                    name="firstName"
                                    rules={[{ required: true, message: 'Please input your first name!' }]}
                                    className='mb-0'
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Last Name"
                                    name="lastName"
                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                    className='mb-0'
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Service Province"
                                    name="serviceProvince"
                                    rules={[{ required: true, message: 'Please select your service province!' }]}
                                    className='mb-0'
                                >
                                    <Select defaultValue={tax} onChange={handleProvince} className='w-full' placeholder="Select Province">
                                        <Option value="13">Alberta</Option>
                                        <Option value="12">Ontario</Option>
                                        <Option value="21">British Columbia</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Service City"
                                    name="serviceCity"
                                    rules={[{ required: true, message: 'Please input your service city!' }]}
                                    className='mb-0'
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='p-3 border border-gray-300 shadow-sm rounded-lg px-6'>
                            <h3 className='font-medium text-[18px] mb-4'>Number Porting</h3>
                            <Checkbox onChange={onNumberPortChange}>Port My Number from a Different Provider</Checkbox>
                            {
                                isPort &&
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-4">
                                    <Form.Item
                                        label="Existing Cell Phone Number"
                                        name="cellno"
                                        rules={[{ required: true, message: 'Please input your cell no!' }]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Service Provider"
                                        name="serviceprovider"
                                        rules={[{ required: true, message: 'Please select service provider' }]}
                                    >
                                        <Select className='w-full' placeholder="Service Provider">
                                            <Option value="Ansatel Communications Inc">Ansatel Communications Inc</Option>
                                            <Option value="Virgin Plus">Virgin Plus</Option>
                                            <Option value="Virgin Mobile">Virgin Mobile</Option>
                                            <Option value="Gaptel Inc">Gaptel Inc</Option>
                                            <Option value="Rogers Communications">Rogers Communications</Option>
                                            <Option value="Bell">Bell</Option>
                                            <Option value="Freedom Mobile">Freedom Mobile</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="flex w-full justify-end">
                        <Button type='primary' className='text-[18px] h-auto' htmlType='submit'>Continue</Button>
                    </div>
                </Form>
                <Modal title="Basic Modal" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <AuthModalView handleCancel={handleCancel} isPort={isPort} values={formData} />
                </Modal>
            </div>
            <Footer />
        </>
    )
}

export default SubscriberInfo;
