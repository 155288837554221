import React, { useState, useMemo, useEffect } from 'react';
import { Radio, Input, Empty, Select } from 'antd';
import heroImg from "../../assets/image/product-list-img.webp";
import ProductCard from '../../components/productCard/ProductCard';
import Preloader from '../../components/proloader/Preloader';
import { useDispatch, useSelector } from 'react-redux';
import ByodCard from '../../components/byodCard/ByodCard';
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom';
import { setProvinceTax } from '../../store/features/user/userSlice';

// Custom hook for debounce
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

const ProductList = () => {
    const [selectedBrand, setSelectedBrand] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const allProducts = useSelector(state => state.products.allProducts);
    const [brands, setBrands] = useState([{ key: 'all', label: 'All', value: 'all' }]);
    const [uniqueProducts, setUniqueProducts] = useState([]);
    const { provinceTax } = useSelector(state => state.user);
    const [loading, setLoading] = useState(true); // Loading state for initial fetch
    const dispatch = useDispatch();


    useEffect(() => {
        if (allProducts) {
            const seenDeviceNames = new Set();
            const uniqueProductsList = allProducts.filter(product => {
                if (!product.devicename_new) return false; // Ignore products without devicename_new
                const isUnique = !seenDeviceNames.has(product.devicename_new);
                if (isUnique) {
                    seenDeviceNames.add(product.devicename_new);
                }
                return isUnique;
            });

            setUniqueProducts(uniqueProductsList);

            const uniqueLabels = new Set(['all']);
            const brand = uniqueProductsList.map(item => {
                const label = item.devicename_new.split(" ")[0];
                if (!uniqueLabels.has(label)) {
                    uniqueLabels.add(label);
                    return {
                        key: label, // Use label as key to avoid index issues
                        label: label,
                        value: label,
                    };
                }
                return null;
            }).filter(brand => brand !== null);

            setBrands([{ key: 'all', label: 'All', value: 'all' }, ...brand, { key: 'byod', label: 'Bring Your Own Device', value: 'byod' }]);

            setTimeout(() => {
                setLoading(false); // Set loading to false after an additional delay
            }, 500); // Half-second delay after allProducts are fetched
        }
    }, [allProducts]);

    const getBrandFromProductName = (productName) => {
        const firstWord = productName.split(' ')[0];
        return firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
    };

    // Debounce delay in milliseconds
    const debounceDelay = 300;

    // Debounced search query
    const debouncedSearchQuery = useDebounce(searchQuery, debounceDelay);

    const filteredProducts = useMemo(() => {
        let filtered = uniqueProducts;

        if (selectedBrand === 'byod') {
            return []; // Return an empty array if BYOD is selected
        }

        if (selectedBrand !== 'all') {
            filtered = filtered.filter(product => getBrandFromProductName(product.devicename_new) === selectedBrand);
        }

        if (debouncedSearchQuery.trim() !== '') {
            const query = debouncedSearchQuery.trim().toLowerCase();
            filtered = filtered.filter(product =>
                product.devicename_new.toLowerCase().includes(query)
            );
        }

        return filtered;
    }, [selectedBrand, debouncedSearchQuery, uniqueProducts]);

    const handleBrandChange = (e) => {
        const value = e.target ? e.target.value : e;
        setSelectedBrand(value);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };


    const handleProvince = (value) => {
        dispatch(setProvinceTax(value));
    }


    if (loading) {
        return <Preloader />;
    }

    return (
        <>
            <div>
                <div className='bg-gray-100'>
                    <p className='p-5 text-center text-themeColor text-[18px]'>Save more.Save the $60 connection fee when you shop Mobility online.5 Plus get fast, free shipping.</p>
                    <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 pt-10 overflow-hidden">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                                <div className="pb-5 sm:pt-[4%] px-3">
                                    <h2 className='heading-2-purp'>Smartphones</h2>
                                    <p>Stay connected with the latest phones - all at $0 upfront plus taxes with <Link target='_blank' className='text-themeColor underline-offset-0' to={"https://www.telus.com/en/support/article/understanding-telus-easy-payment"}>Tell Us Easy Payment</Link>.</p>
                                </div>
                            </div>
                            <div className='flex flex-col-reverse items-end'>
                                <img src={heroImg} alt="products" className="object-cover w-full h-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='my-4 mx-auto px-5 pt-5 w-full'>
                    <h2 className='font-medium mb-2 md:mb-0'>Select Province</h2>
                    <Select
                        defaultValue={provinceTax}
                        placeholder="Select Province"
                        onChange={handleProvince}
                        className='w-full sm:w-[200px]'
                    >
                        <Select.Option value="13">Alberta</Select.Option>
                        <Select.Option value="12">Ontario</Select.Option>
                        <Select.Option value="21">British Columbia</Select.Option>
                    </Select>
                </div>


                <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 py-8 overflow-hidden">
                    <div className="flex flex-col items-center">
                        <p className='text-lg font-bold mb-4'>Filter By</p>
                        <Input
                            placeholder="Search products"
                            value={searchQuery}
                            onChange={handleSearch}
                            style={{ width: 200 }}
                            className='mb-5'
                        />
                        <Radio.Group buttonStyle="solid" onChange={handleBrandChange} value={selectedBrand} className="space-x-2 max-md:hidden">
                            {brands.map(brand => (
                                <Radio.Button key={brand.key} value={brand.value} className="custom-radio-button">{brand.label}</Radio.Button>
                            ))}
                        </Radio.Group>
                        <Select value={selectedBrand} onChange={handleBrandChange} className='w-full md:hidden'>
                            {brands && brands.map(brand => (
                                <Select.Option key={brand.key} value={brand.value}>{brand.label}</Select.Option>
                            ))}
                        </Select>
                    </div>
                    {filteredProducts.length === 0 && selectedBrand !== "byod" ? (
                        <Empty description="No products found" className='h-screen mt-8' />
                    ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 sm:my-8 my-6">
                            {filteredProducts.map(product => (
                                <ProductCard key={product.id} product={product} />
                            ))}
                            {(selectedBrand === "all" || selectedBrand === "byod") && <ByodCard />}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ProductList;