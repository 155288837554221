import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProductCard from '../../components/productCard/ProductCard';
import Preloader from '../../components/proloader/Preloader';

const LatestProducts = () => {
    const allProducts = useSelector(state => state.products.allProducts);
    const [latestProducts, setLatestProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (allProducts) {
            // Filter the products for Apple, Samsung, and Google Pixel
            const filteredProducts = allProducts.filter(product => {
                const productName = product.devicename_new.toLowerCase();
                return (
                    productName.includes('apple') ||
                    productName.includes('samsung') ||
                    productName.includes('pixel')
                );
            });

            // Get the first 2 Apple, 1 Samsung, and 1 Google Pixel products
            const appleProducts = filteredProducts.filter(product => product.devicename_new.toLowerCase().includes('apple')).slice(0, 2);
            const samsungProduct = filteredProducts.find(product => product.devicename_new.toLowerCase().includes('samsung'));
            const googlePixelProduct = filteredProducts.find(product => product.devicename_new.toLowerCase().includes('pixel'));

            // Combine the products
            const selectedProducts = [...appleProducts, samsungProduct, googlePixelProduct].filter(Boolean);

            setLatestProducts(selectedProducts);
            setTimeout(() => setLoading(false), 500); // Simulating a slight delay for loading state
        }
    }, [allProducts]);

    if (loading) {
        return <Preloader />;
    }

    return (
        <>
            <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 py-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                    {latestProducts.map(product => (
                        <ProductCard key={product.id} product={product} />
                    ))}
                </div>
            </div>
        </>
    );
};

export default LatestProducts;