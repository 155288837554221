import React, { useEffect, useState } from "react";
import { Button, Radio, message } from "antd";
import { Switch, Card } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Preloader from "../proloader/Preloader";
import { setCart } from "../../store/features/cart/cartSlice";
import axios from "axios";
import byodImg from "../../assets/image/byod.png";
import Footer from "../footer/Footer";

const SingleProduct = () => {

    const [selectedColor, setSelectedColor] = useState("");
    const [selectedStorage, setSelectedStorage] = useState("");
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [singleProduct, setSingleProduct] = useState(null);
    const [bringItBack, setBringItBack] = useState(false);
    const [productVarients, setProductVarient] = useState([]);
    const [productImage, setProductImage] = useState("");
    const [upfront, setUpfront] = useState("");
    const [lowMonthly, setLowMonthly] = useState("");
    const { allProducts } = useSelector(state => state.products);
    const { activeProduct } = useSelector(state => state.cart);
    const [isByod, setIsByod] = useState("No");
    const [devicePlans, setDevicePlans] = useState([]);
    const [filteredPlans, setFilteredPlans] = useState([]);
    const [hardwareDiscount, setHardwareDiscount] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getallplans.php`);
                const plans = response.data;

                if (activeProduct === "byod") {
                    setIsByod("Yes");
                }

                const completePlans = [
                    { ...plans[0], data: "60GB", speed: "5G+", bonus: "Int'l msg", eligible: "All (Except Standard-E)" },
                    { ...plans[1], data: "75GB", speed: "5G+", bonus: "Int'l msg", eligible: "All (Except Standard B,D, and E)" },
                    { ...plans[2], data: "100GB", speed: "5G+", bonus: "Int'l msg", eligible: "All (Except Standard-E)" },
                    { ...plans[3], data: "100GB", speed: "5G+", bonus: "Int'l msg", eligible: "All (Except Standard-E)" },
                    { ...plans[4], data: "150GB", speed: "5G+", bonus: "Int'l msg", eligible: "All (Except Standard-E)" }
                ];

                setDevicePlans(completePlans);
            } catch (error) {
                console.error("Error fetching plans:", error);
                // Optionally set an error state or show an error message to the user
            }
        };

        fetchPlans();
    }, [activeProduct]);

    useEffect(() => {

        if (devicePlans.length) {
            if (isByod === "No") {
                setFilteredPlans(devicePlans.filter(plan => +plan.rate !== 0));
            } else {
                setFilteredPlans(devicePlans.filter(plan => +plan.byod_rate !== 0));
            }
        }
    }, [isByod, devicePlans]);


    useEffect(() => {
        if (allProducts && activeProduct && activeProduct !== "byod") {
            const product = allProducts.find(product => +product.id === +activeProduct);
            const productArr = allProducts.filter(item => item.devicename_new === product.devicename_new);
            setProductVarient(productArr);
            setSingleProduct(product);
            if (product) {
                setProductImage(product.device_images[0]);
            }
        }
    }, [activeProduct, allProducts]);

    useEffect(() => {
        if (singleProduct && activeProduct && activeProduct !== "byod") {
            const product = allProducts.find(product => +product.id === +activeProduct);
            if (bringItBack) {
                setUpfront(product.zero_up_monthly_bib);
                setLowMonthly(product.low_monthly_monthly_bib);
            } else {
                setUpfront(product.zero_up_monthly_non_bib);
                setLowMonthly(product.low_monthly_monthly_non_bib);
            }
        }
    }, [singleProduct, bringItBack, allProducts, activeProduct]);

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };

    const handlePaymentSelectCard = (card) => {
        setSelectedPayment(card);
    };

    const onbringItBackChange = (checked) => {
        setBringItBack(checked);
    }

    const onByodChange = (checked) => {
        setIsByod(checked ? "Yes" : "No");
    }

    const handleStorageChange = (storage) => {
        setSelectedStorage(storage);
        setSingleProduct(allProducts.find((prod) => singleProduct.devicename_new === prod.devicename_new && storage === prod.memory));
    }

    const handleColorChange = (color) => {
        setSelectedColor(color.name);
        setProductImage(singleProduct.device_images[color.index]);
    }

    const buyNow = () => {

        if (isByod === "Yes") {
            if (selectedPlan) {

                const payload = {
                    brand: "",
                    device_name: "",
                    color: "",
                    storage: "",
                    payment: {
                        id: "",
                        name: "",
                        price: "",
                        due: ""
                    },
                    plan: {
                        title: selectedPlan.name,
                        price: isByod === "Yes" ? selectedPlan.byod_rate : selectedPlan.rate,
                        details: isByod === "Yes" ? selectedPlan.byod_category : selectedPlan.category,
                        id: selectedPlan.id,
                        data: selectedPlan.data,
                        speed: selectedPlan.speed,
                        bonus: selectedPlan.bonus,
                        eligible: selectedPlan.eligible,
                    },
                    image: "",
                    bringItBack: "",
                    byod: isByod === "Yes" ? true : false,
                }
                dispatch(setCart(payload));
                navigate("/subscriberinfo");
                return;

            } else {
                message.error("Plase Select Plan");
            }
        } else {
            if ((selectedColor || !singleProduct.color) && (selectedStorage || !singleProduct.memory) && selectedPayment && selectedPlan) {
                if (!selectedColor) {
                    message.error("Please Select All Options");
                    return;
                }
                const payload = {
                    brand: singleProduct.devicename.split(" ")[0],
                    device_name: singleProduct.devicename_new,
                    color: selectedColor,
                    storage: selectedStorage,
                    payment: selectedPayment,
                    plan: {
                        title: selectedPlan.name,
                        price: isByod === "Yes" ? selectedPlan.byod_rate : selectedPlan.rate,
                        details: isByod === "Yes" ? selectedPlan.byod_category : selectedPlan.category,
                        id: selectedPlan.id,
                        data: selectedPlan.data,
                        speed: selectedPlan.speed,
                        bonus: selectedPlan.bonus,
                        eligible: selectedPlan.eligible,
                    },
                    image: process.env.REACT_APP_BASE_URL + "/images/" + productImage,
                    bringItBack: bringItBack,
                    byod: isByod === "Yes" ? true : false,
                }
                dispatch(setCart(payload));
                navigate("/subscriberinfo");
            } else {
                message.error("Please Select All Options");
            }
        }

    }

    const payments = [
        {
            id: "1",
            name: "pay $0 upfront",
            price: +upfront,
            due: "monthly"
        },
        {
            id: "2",
            name: "lower monthly",
            price: +lowMonthly,
            due: "monthly"
        }
    ]





    useEffect(() => {
        if (singleProduct) {
            const discountKeys = [
                'low_monthly_hd_bib',
                'low_monthly_hd_non_bib',
                'zero_up_hd_bib',
                'zero_up_hd_non_bib'
            ];

            const maxDiscount = discountKeys.reduce((max, key) => {
                const value = parseFloat(singleProduct[key]);
                return value > max ? value : max;
            }, 0);

            setHardwareDiscount(maxDiscount);


            if (bringItBack) {

                if (selectedPayment && selectedPayment.id === "1") {
                    setHardwareDiscount(+singleProduct.low_monthly_hd_bib);
                }
                if (selectedPayment && selectedPayment.id === "2") {
                    setHardwareDiscount(+singleProduct.zero_up_hd_bib);
                }

            } else {

                if (selectedPayment && selectedPayment.id === "1") {
                    setHardwareDiscount(+singleProduct.low_monthly_hd_non_bib);
                }
                if (selectedPayment && selectedPayment.id === "2") {
                    setHardwareDiscount(+singleProduct.zero_up_hd_non_bib);

                }

            }

        }



    }, [singleProduct, selectedPayment, bringItBack]);


    if ((!devicePlans.length || !singleProduct) && activeProduct !== "byod") {
        return <Preloader />;
    }


    return (
        <>
            {activeProduct !== "byod" ?
                <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 py-8 bg-white shadow-sm rounded-lg overflow-hidden ">
                    <div className="flex flex-col lg:flex-row items-center">
                        <div className="w-full lg:w-[50%] p-4">
                            <div className="relative">
                                <div className="flex justify-center">
                                    <img src={process.env.REACT_APP_BASE_URL + "/images/" + productImage} alt={productImage} className="w-[300px] sm:w-[450px]" />
                                </div>
                                <div className="absolute top-4 left-4 bg-themeColor text-white text-xs font-semibold px-2 py-1 rounded-br-lg">
                                    Bring-It-Back
                                </div>
                                {hardwareDiscount !== 0 &&
                                    <div className="absolute bottom-4 left-4 bg-themeColor text-white text-xs font-semibold w-[100px] h-[100px] rounded-full flex justify-center items-center">
                                        <p className="text-center">
                                            <span className="text-sm text-[#c7dda9] block font-bold">${hardwareDiscount}</span>
                                            <span className="text-sm text-white block leading-tight font-light ">Hardware Discount</span>
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="w-full lg:w-[50%] p-4 max-sm:mt-5">
                            <h2 className="mb-0 font-semibold text-gray-800">{singleProduct.devicename_new.split(" ")[0]}</h2>
                            <p className="heading-2-purp font-[200] text-themeColor mb-4">
                                {singleProduct.devicename_new}
                            </p>
                            {singleProduct.memory &&
                                <div className="mb-5">
                                    <h3 className="mb-3">Memory options: {selectedStorage}</h3>
                                    <Radio.Group
                                        value={selectedStorage}
                                        onChange={(e) => handleStorageChange(e.target.value)}
                                        className="flex space-x-2 mt-2"
                                        buttonStyle="solid"
                                    >
                                        {productVarients.map((varient) => (
                                            <Radio.Button key={varient.memory} value={varient.memory}>
                                                {varient.memory}
                                            </Radio.Button>
                                        ))}
                                    </Radio.Group>
                                </div>
                            }
                            <div className="mb-5">
                                <h3 className="mb-3">Colour options: {selectedColor}</h3>
                                <div className="flex gap-2 mt-2 flex-wrap">
                                    {singleProduct.color_array.map((color, index) => (
                                        <span
                                            key={color}
                                            className={`border mr-2 p-2 border-gray-400 cursor-pointer ${selectedColor === color ? "ring-2 ring-themeColor" : ""}`}
                                            onClick={() => handleColorChange({ name: color, index })}
                                        >{color}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="my-6 sm:pt-[80px]">
                        <div className="pb-4 border-b border-themeColor">
                            <div>
                                <h2 className="text-[20px] sm:text-[25px] font-medium text-themeColor">
                                    Get the lowest monthly price with Bring‑It‑Backᵀᴹ
                                </h2>
                            </div>
                            <div className="flex gap-5 mt-5 items-center">
                                <p className="text-[18px] text-gray-700 font-[300]">
                                    Apply Bring‑It‑Back™ pricing
                                </p>
                                <Switch checkedChildren="Yes" onChange={onbringItBackChange} unCheckedChildren="No" />
                                {bringItBack && singleProduct.bib_amount !== "0" &&
                                    <h3 className="duration-1000">You have Saved ${singleProduct.bib_amount}</h3>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="my-6 sm:pt-[20px] capitalize">
                        <div className="pb-4 border-b border-themeColor">
                            <h2 className="text-[20px] sm:text-[25px] font-medium text-themeColor">
                                TELUS Easy Payment
                            </h2>
                            <div className="flex flex-wrap gap-4 mt-6 mb-3">
                                {payments.map((payment) => (
                                    <div key={payment.id} className="w-full sm:w-[45%] mb-6 sm:mb-0">
                                        <Card
                                            className={` ${selectedPayment?.id === payment.id ? "border-2 border-themeColor" : "border-2 border-gray-300"}`}
                                            hoverable
                                            onClick={() => handlePaymentSelectCard(payment)}
                                        >
                                            <div className="p-4">
                                                <h3 className="text-xl font-semibold text-themeColor mb-3">
                                                    {payment.name}
                                                </h3>
                                                <div className="flex items-center">
                                                    <Radio
                                                        checked={selectedPayment?.id === payment.id}
                                                        onChange={() => handlePaymentSelectCard(payment)}
                                                    />
                                                    <p className="text-lg ml-2">${payment.price} Due {payment.due}</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-5 mt-5 items-center">
                        <p className="text-[18px] text-gray-700 font-[300]">
                            Apply BYOD pricing
                        </p>
                        <Switch checkedChildren="Yes" onChange={onByodChange} unCheckedChildren="No" />
                    </div>

                    <div className="my-6 sm:pt-[20px]">
                        <div className="pb-6 border-b border-themeColor">
                            <div>
                                <h2 className="text-[20px] sm:text-[25px] font-medium text-themeColor">
                                    Select your plan UnlimitedTM Plans
                                </h2>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 ">
                                {filteredPlans.length > 0 && filteredPlans.map((item) => (
                                    <div className="h-full" key={item.id}>
                                        <Card
                                            className={`h-full ${selectedPlan?.id === item.id ? "border-2 border-themeColor" : "border-2 border-gray-300"}`}
                                            hoverable
                                            onClick={() => handlePlanSelect(item)}
                                        >
                                            <div className="w-full">
                                                <h3 className="text-xl font-semibold text-themeColor mb-3">
                                                    {item.name}
                                                </h3>
                                                <p className="font-medium my-1 text-[13px]">Data : {item.data}</p>
                                                <p className="font-medium my-1 text-[13px]">Speed : {item.speed}</p>
                                                <p className="font-medium my-1 text-[13px]">Bonus Features : {item.bonus}</p>
                                                <p className="font-bold my-1 text-[13px]">Eligible Organizations : <span className="font-medium my-1 text-[13px] block">{item.eligible}</span></p>
                                                <div className="flex items-center">
                                                    <Radio
                                                        checked={selectedPlan?.id === item.id}
                                                        onChange={() => handlePlanSelect(item)}
                                                    />
                                                    <p className="ml-2 font-medium text-lg">${isByod === "Yes" ? item.byod_rate : item.rate}</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="my-5">
                        <Button onClick={buyNow} type="primary" className="w-full text-[18px] uppercase h-auto">
                            Buy Now
                        </Button>
                    </div>
                </div>
                : <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 py-8 bg-white shadow-sm rounded-lg overflow-hidden ">
                    <div className="flex flex-col lg:flex-row items-center">
                        <div className="w-full lg:w-[50%] p-4">
                            <div className="relative">
                                <div className="flex justify-center">
                                    <img src={byodImg} alt="bring your own device" className="w-[200px] sm:w-[250px]" />
                                </div>
                                <div className="absolute top-4 left-4 bg-themeColor text-white text-xs font-semibold px-2 py-1 rounded-br-lg">
                                    Bring your own device
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-[50%] p-4 max-sm:mt-5">
                            <h2 className="heading-2-purp font-[200] text-themeColor mb-4">Bring Your Own Device</h2>
                            <p>Join our fast and reliable network and get any unlimited phone plan with no contract.</p>
                        </div>
                    </div>

                    <div className="my-6 sm:pt-[20px]">
                        <div className="pb-6 border-b border-themeColor">
                            <div>
                                <h2 className="text-[20px] sm:text-[25px] font-medium text-themeColor">
                                    Select your plan UnlimitedTM Plans
                                </h2>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 ">
                                {filteredPlans.length > 0 && filteredPlans.map((item) => (
                                    <div className="h-full" key={item.id}>
                                        <Card
                                            className={`h-full ${selectedPlan?.id === item.id ? "border-2 border-themeColor" : "border-2 border-gray-300"}`}
                                            hoverable
                                            onClick={() => handlePlanSelect(item)}
                                        >
                                            <div className="w-full">
                                                <h3 className="text-xl font-semibold text-themeColor mb-3">
                                                    {item.name}
                                                </h3>
                                                <p className="font-medium my-1 text-[13px]">Data : {item.data}</p>
                                                <p className="font-medium my-1 text-[13px]">Speed : {item.speed}</p>
                                                <p className="font-medium my-1 text-[13px]">Bonus Features : {item.bonus}</p>
                                                <p className="font-bold my-1 text-[13px]">Eligible Organizations : <span className="font-medium my-1 text-[13px] block">{item.eligible}</span></p>
                                                <div className="flex items-center">
                                                    <Radio
                                                        checked={selectedPlan?.id === item.id}
                                                        onChange={() => handlePlanSelect(item)}
                                                    />
                                                    <p className="ml-2 font-medium text-lg">${isByod === "Yes" ? item.byod_rate : item.rate}</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="my-5">
                        <Button onClick={buyNow} type="primary" className="w-full text-[18px] uppercase h-auto">
                            Buy Now
                        </Button>
                    </div>
                </div >}

            <Footer />
        </>
    );
};

export default SingleProduct;
