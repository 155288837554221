import React from 'react';
import logo from "../../assets/image/logo.webp";
import { Button, Form, Input, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { UserOutlined, LockOutlined, KeyOutlined } from "@ant-design/icons";
import usePost from '../../hooks/usePost';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import tellLogo from "../../assets/image/telus.jpg";
import { setCurrentUser } from '../../store/features/user/userSlice';
import Footer from '../../components/footer/Footer';

const Login = () => {

    const [form1] = useForm();
    const [form2] = useForm();
    const { post: customerLoginPost, loading: loginLoading } = usePost();
    const { post: keyLoginPost, loading: keyLoading } = usePost();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const customerLogin = async (values) => {
        const resp = await customerLoginPost("/loginx.php", values);
        if (resp.status === "success") {
            dispatch(setCurrentUser(resp.message[0]));
            navigate("/productlist");
            message.success("Loged In Successfully");
        } else {
            message.error("Invalid Credentials");
        }
    }

    const loginWithKey = async (values) => {
        const resp = await keyLoginPost("/checkaccesskey.php", { accesskey: values.key });
        if (resp.length > 0 && Object.keys(resp[0]).length > 0) {
            navigate("/productlist");
            dispatch(setCurrentUser(resp[0]));
        } else {
            message.error("Invalid Key");
        }
    }

    return (
        <>
            <div className='container mx-auto my-[25px] sm:my-[40px] max-w-[1150px] px-[20px]'>
                <div className="flex justify-center gap-8 items-end">
                    <img src={logo} alt="boltwireless" className='w-[150px]' />
                    <img src={tellLogo} alt="tell us" className='w-[150px]' />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 mt-10 p-3 border border-gray-300 shadow-sm rounded-lg">
                    <div>
                        <div className='p-6 gap-6'>
                            <h2 className='text-[22px] font-bold text-themeColor mb-4'>Login With Email </h2>

                            <h3 className='font-light text-[16px] mb-4'>Returning users that placed an order before can login with email and password</h3>
                            <Form
                                form={form1}
                                name="loginform"
                                initialValues={{ remember: true }}
                                onFinish={customerLogin}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: 'Please enter your email' }]}
                                >
                                    <Input
                                        className='py-2 text-[16px]'
                                        prefix={<UserOutlined className='text-gray-400' />}
                                        placeholder="Enter your email"
                                        autoComplete='false'
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Please enter your password!' }]}
                                >
                                    <Input.Password
                                        className='py-2 text-[16px]'
                                        prefix={<LockOutlined className='text-gray-400' />}
                                        placeholder="Enter your password"
                                        autoComplete='false'
                                    />
                                </Form.Item>

                                <div className="flex justify-end mb-4">
                                    <label className='fw-medium text-[16px] text-themeColor'>Forgot Password</label>
                                </div>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="w-full bg-themeBlue text-[18px] py-1 font-medium h-full hover:bg-themeRed duration-300"
                                        disabled={loginLoading}
                                        loading={loginLoading}
                                    >
                                        Login
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    <div>
                        <div className='p-6 gap-6'>
                            <h2 className='text-[22px] font-bold text-themeColor mb-4'>Login With Access Key <KeyOutlined /> </h2>
                            <h3 className='font-light text-[16px] mb-4'>First time users or new users, please enter your company's EPP Access Code here</h3>
                            <Form
                                form={form2}
                                name="keyloginform"
                                initialValues={{ remember: true }}
                                onFinish={loginWithKey}
                            >
                                <Form.Item
                                    name="key"
                                    rules={[{ required: true, message: 'Please your key' }]}
                                >
                                    <Input
                                        className='py-2 text-[16px]'
                                        prefix={<KeyOutlined className='text-gray-400' />}
                                        placeholder="Enter your key"
                                        autoComplete='false'
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="w-full bg-themeBlue text-[18px] py-1 font-medium h-full hover:bg-themeRed duration-300"
                                        disabled={keyLoading}
                                        loading={keyLoading}
                                    >
                                        Go
                                    </Button>
                                </Form.Item>
                                <p className='my-3 text-[17px] font-bold'>Don't Have Access Key ?</p>
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    size='large'
                                    className='flex justify-center items-center'
                                    disabled={keyLoading}
                                    loading={keyLoading}
                                    onClick={() => navigate("/eligibleform")}
                                >
                                    <span>Get Access Key</span> <KeyOutlined className='text-white font-bold text-[20px]' />
                                </Button>
                                {/* <div>
                                    <p className='font-medium mb-3'>For Assistace</p>
                                    <p>Email : <a className='text-themeColor' href='mailto:info@boltwireless.cas'>info@boltwireless.cas</a></p>
                                    <p>Contact : <a className='text-themeColor' href='tel:778 957 8000'>778 957 8000</a></p>
                                </div> */}
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default Login;