import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentUser: null,
    isGuest: false,
    provinceTax: "13",
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        setIsGuest: (state, action) => {
            state.isGuest = action.payload;
        },
        setProvinceTax: (state, action) => {
            state.provinceTax = action.payload;
        }
    }
});

export const { setCurrentUser, setIsGuest, setProvinceTax } = userSlice.actions;
export default userSlice.reducer;
