import React, { useEffect, useState } from 'react';
import PreLoader from "../../components/proloader/Preloader";
import axios from 'axios';
import { useSelector } from 'react-redux';

const OrderDetail = () => {

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const { activeOrder } = useSelector(state => state.cart);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const urls = [
                    `${process.env.REACT_APP_BASE_URL}/getsingleorder.php`,
                    `${process.env.REACT_APP_BASE_URL}/getplan.php`,
                    `${process.env.REACT_APP_BASE_URL}/getpayment.php`,
                    `${process.env.REACT_APP_BASE_URL}/getdevice.php`,
                    `${process.env.REACT_APP_BASE_URL}/getsubscriberinfo.php`
                ];

                const requests = urls.map(url => axios.post(url, { customer_id: activeOrder }));
                const [orderDetail, plan, payment, device, customer_info] = await Promise.all(requests);

                setData({
                    orderDetail: orderDetail.data[0],
                    plan: plan.data[0],
                    payment: payment.data[0],
                    device: device.data[0],
                    customer_info: customer_info.data[0]
                });
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        if (activeOrder) {
            fetchData();
        }
    }, [activeOrder]);

    if (loading) {
        return <PreLoader />
    }

    return (
        <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 py-8 bg-white overflow-hidden">
            <h2 className="text-[18px] sm:text-[22px] text-themeColor font-medium mb-5">Order Details</h2>
            <div className="grid grid-cols-12 border sm:gap-8 border-gray-400 shadow-sm p-5 rounded-md">
                {data.orderDetail.byod === "0" &&
                    <div className='col-span-12 sm:col-span-6 md:col-span-2 flex justify-center'>
                        <img src={data.device.image} className='w-[150px]' alt="phone img" />
                    </div>
                }
                {data.orderDetail.byod === "0" &&

                    <div className='capitalize col-span-12 sm:col-span-6 md:col-span-5'>
                        <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>brand : {data.device.brand}</p>
                        <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>device name : {data.device.device_name}</p>
                        <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>color : {data.device.color}</p>
                        <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>memory : {data.device.storage === "1" ? data.device.storage + "TB" : data.device.storage + "GB"}</p>
                        <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>Bring it Back : {+data.device.bringItBack === 1 ? "Yes" : "No"}</p>
                    </div>
                }
                <div className={data.orderDetail.byod === "0" ? `capitalize col-span-12 md:col-span-5` : "`capitalize col-span-12 "}>
                    {data.orderDetail.byod === "0" && <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>Payment Type : {data.payment.name}</p>}
                    {data.orderDetail.byod === "0" &&
                        <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>Price : ${data.payment.price + "/" + data.payment.due}</p>

                    }
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>Plan : {data.plan.title}</p>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>Plan Price : ${data.plan.price}/month</p>
                </div>
            </div>

            <h2 className="text-[18px] sm:text-[22px] text-themeColor font-medium my-5">Customer Info</h2>

            <div className="grid grid-cols-1 md:grid-cols-2 border gap-8 border-gray-400 shadow-sm p-5 rounded-md">
                <div className='capitalize'>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>first name : {data.orderDetail.firstName}</p>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>last name : {data.orderDetail.lastName}</p>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>Email : {data.orderDetail.email}</p>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>Date Of Birth : {data.orderDetail.dateOfBirth}</p>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Work Space: {data.orderDetail.workspace}</p>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Is Porting: {data.customer_info.isPorting === "1" ? "Yes" : "No"}</p>
                    {data.customer_info.isPorting === "1" && <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Cell No: {data.customer_info.cellno} </p>}
                </div>
                <div className='capitalize'>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>is adult : {data.orderDetail.isAdult}</p>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>Credit Card No : {data.orderDetail.creditCardNo}</p>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>card expriry month : {data.orderDetail.cartExpMonth}</p>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> card expiry year: {data.orderDetail.cardExpYear}</p>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>identity : {data.orderDetail.identity}</p>
                    <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>ID No : {data.orderDetail.id_number}</p>
                    {data.customer_info.isPorting === "1" && <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Service Provider: {data.customer_info.serviceprovider} </p>}
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <h2 className="text-[18px] sm:text-[22px] text-themeColor font-medium my-5">Billing Info</h2>

                    <div className="grid grid-cols-1 border gap-8 border-gray-400 shadow-sm p-5 rounded-md">
                        <div className='capitalize'>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>street no : {data.orderDetail.streetNo}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>street no suffix : {data.orderDetail.streetNoSuffix}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>street name : {data.orderDetail.streetName}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>street type : {data.orderDetail.streetType}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> floor no: {data.orderDetail.floorNo}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> unit : {data.orderDetail.unit}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Buzzar No: {data.orderDetail.buzzarNo}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> city : {data.orderDetail.city}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> province : {data.orderDetail.province}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Country : {data.orderDetail.country}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Postal Code: {data.orderDetail.postalCode}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Day Contact No: {data.orderDetail.dayContactNo}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Day Contact No Exit: {data.orderDetail.dayExitContactNo}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Attention: {data.orderDetail.attention}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className="text-[18px] sm:text-[22px] text-themeColor font-medium my-5">Shipping Info</h2>
                    <div className="grid grid-cols-1 border gap-8 border-gray-400 shadow-sm p-5 rounded-md">
                        <div className='capitalize'>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>street no : {data.orderDetail.streetNo_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>street no suffix : {data.orderDetail.streetNoSuffix_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>street name : {data.orderDetail.streetName_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'>street type : {data.orderDetail.streetType_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> floor no: {data.orderDetail.floorNo_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> unit : {data.orderDetail.unit_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Buzzar No: {data.orderDetail.buzzarNo_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> city : {data.orderDetail.city_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> province : {data.orderDetail.province_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Country : {data.orderDetail.country_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Postal Code: {data.orderDetail.postalCode_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Day Contact No: {data.orderDetail.dayContactNo_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Day Contact No Exit: {data.orderDetail.dayExitContactNo_ship}</p>
                            <p className='mb-2 pb-2 border-b border-gray-400 font-light text-[14px]'> Attention: {data.orderDetail.attention_ship}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default OrderDetail;
