import React from 'react'
import byodImg from "../../assets/image/byod.png";
import { FaArrowRight } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setActiveProduct } from '../../store/features/cart/cartSlice';

const ByodCard = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleByod = () => {
        dispatch(setActiveProduct("byod"));
        navigate("/singleproduct");
    }

    return (
        <div onClick={handleByod} className="cursor-pointer w-full mx-auto bg-[#F4F4F7] shadow-sm border border-gray-300 rounded-lg overflow-hidden">
            <div className="relative p-4">
                <span className="absolute top-0 left-0 bg-themeColor text-white text-xs font-semibold px-2 py-1 rounded-br-lg">On sale</span>
                <div className="flex justify-center mt-4">
                    <img className="object-cover w-[150px]" src={byodImg} alt="bring your own device" />
                </div>
            </div>
            <div className="p-4">
                <h2 className="text-lg font-semibold text-gray-800">Bring your own device.</h2>
                <p className="text-gray-600 text-[12px]">Join our fast and reliable network and get any unlimited phone plan with no contract.</p>
                <p className='text-[#2B8000] my-3 text-[14px] font-medium flex gap-2 items-center duration-300 hover:text-themeColor'><span>Bring your own device</span> <FaArrowRight /></p>
            </div>
        </div>
    )
}

export default ByodCard