import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Input, Modal, Space, Table, Tabs } from 'antd';
import { FaSearch } from 'react-icons/fa';
import { FaEllipsisVertical } from "react-icons/fa6";
import axios from 'axios';
import { setActiveOrder } from '../../store/features/cart/cartSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const { TabPane } = Tabs;

const Admin = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeStatus, setActiveStatus] = useState("");
    const [order, setOrder] = useState(null);
    const [statusToggler, setStatusToggler] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/getorders.php`);
                if (resp.status === 200) {
                    setData(resp.data);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [statusToggler]);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<FaSearch />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters();
                            confirm();
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <FaSearch style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
    });

    const handleStatus = async () => {
        setIsModalOpen(true);
        setActiveStatus(order.status);
    }

    const gototDetail = () => {
        dispatch(setActiveOrder(order.id));
        navigate("/orderdetail");
    }

    const items = [
        {
            label: <p onClick={gototDetail}>View Details</p>,
            key: '0',
        },
        {
            label: <p onClick={handleStatus}>Change Status</p>,
            key: '1',
        }
    ];

    const columns = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'name',
            sorter: (a, b) => a.customer_info.firstName.localeCompare(b.customer_info.firstName),
            ...getColumnSearchProps("customer_info.firstName"),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Device',
            dataIndex: 'device_name',
            key: 'device_name',
            render: (text) => text ? text : 'Sim Order',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Dropdown
                    menu={{
                        items,
                    }}
                    trigger={['click']}
                >
                    <div className='cursor-pointer hover:text-theme'>
                        <Space className='w-full flex justify-center' onClick={() => setOrder(record)}>
                            <FaEllipsisVertical />
                        </Space>
                    </div>
                </Dropdown>
            ),
        },
    ];

    const handleStatusChange = (orderStatus) => {
        setActiveStatus(orderStatus);
    }

    const updateStatus = async () => {
        setStatusLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/updatestatus.php`, { customer_id: order.id, status: activeStatus });
            setStatusToggler(!statusToggler);
        } catch (error) {
            console.error(error);
        }
        setIsModalOpen(false);
        setStatusLoading(false);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const handleTabChange = (key) => {
        setActiveTab(key);
    }

    // Filter orders by status
    const completedOrders = data.filter(order => order.status === 'completed');
    const pendingOrders = data.filter(order => order.status === 'pending');
    const processOrders = data.filter(order => order.status === 'process');

    // if (loading) {
    //     return <Preloader />
    // }

    return (
        <div className="max-w-7xl mx-auto container px-4 sm:px-6 lg:px-8 py-8 bg-white overflow-hidden">
            <h2 className="text-[18px] sm:text-[22px] text-primary font-medium mb-5">Orders Information</h2>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="Pending" key="1">
                    {pendingOrders.length > 0 ? (
                        <>
                            <h3 className="text-[16px] sm:text-[20px] text-primary font-medium my-4">Pending Orders</h3>
                            <Table
                                columns={columns}
                                rowKey={(record) => record.id}
                                dataSource={pendingOrders}
                                pagination={tableParams.pagination}
                                loading={loading}
                                onChange={handleTableChange}
                                className='capitalize overflow-x-auto'
                            />
                        </>
                    ) : (
                        loading ? <p>Loading...</p> : <p>No Pending Orders</p>
                    )}
                </TabPane>
                <TabPane tab="Process" key="2">
                    {processOrders.length > 0 ? (
                        <>
                            <h3 className="text-[16px] sm:text-[20px] text-primary font-medium my-4">Processing Orders</h3>
                            <Table
                                columns={columns}
                                rowKey={(record) => record.id}
                                dataSource={processOrders}
                                pagination={tableParams.pagination}
                                loading={loading}
                                onChange={handleTableChange}
                                className='capitalize overflow-x-auto'
                            />
                        </>
                    ) : (
                        loading ? <p>Loading...</p> : <p>No Processing Orders</p>

                    )}
                </TabPane>
                <TabPane tab="Completed" key="3">
                    {completedOrders.length > 0 ? (
                        <>
                            <h3 className="text-[16px] sm:text-[20px] text-primary font-medium my-4">Completed Orders</h3>
                            <Table
                                columns={columns}
                                rowKey={(record) => record.id}
                                dataSource={completedOrders}
                                pagination={tableParams.pagination}
                                loading={loading}
                                onChange={handleTableChange}
                                className='capitalize overflow-x-auto'
                            />
                        </>
                    ) : (
                        loading ? <p>Loading...</p> : <p>No Completed Orders</p>
                    )}
                </TabPane>
            </Tabs>
            <Modal
                title="Set Order Status"
                okText="Update Status"
                centered
                open={isModalOpen}
                onOk={updateStatus}
                onCancel={handleCancel}
                confirmLoading={statusLoading}
            >
                <div className='text-center px-4 py-2 gap-3 flex justify-center items-center'>
                    <div className="space-y-4">
                        <div>
                            <button
                                className={`p-1 m-2 rounded-md border border-themeColor hover:bg-themeColor hover:text-white font-medium ${activeStatus === 'pending' ? 'bg-themeColor text-white' : 'bg-white text-themeColor'
                                    }`}
                                onClick={() => handleStatusChange("pending")}
                            >
                                Pending
                            </button>
                            <button
                                className={`p-1 m-2 rounded-md border border-themeColor hover:bg-themeColor hover:text-white font-medium ${activeStatus === 'process' ? 'bg-themeColor text-white' : 'bg-white text-themeColor'
                                    }`}
                                onClick={() => handleStatusChange("process")}
                            >
                                Process
                            </button>
                            <button
                                className={`p-1 m-2 rounded-md border border-themeColor hover:bg-themeColor hover:text-white font-medium ${activeStatus === 'completed' ? 'bg-themeColor text-white' : 'bg-white text-themeColor'
                                    }`}
                                onClick={() => handleStatusChange("completed")}
                            >
                                Completed
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Admin;
